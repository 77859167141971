import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';

import { withRouter } from 'react-router-dom';

export class Header extends Component {
  handleBackClick = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes, title, hiddenBtnBack } = this.props;
    return (
      <Fragment>
        <AppBar position="fixed" className={classes.root}>
          <Toolbar className={classes.toolbar}>
            {hiddenBtnBack ? (
              <div className={classes.divHideBtnBack}></div>
            ) : (
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={this.handleBackClick}>
                <ChevronLeftIcon />
              </IconButton>
            )}
            <Typography variant="h6" className={classes.title}>
              {title || 'eXtaPlus'}
            </Typography>
          </Toolbar>
        </AppBar>
      </Fragment>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  history: PropTypes.object,
  hiddenBtnBack: PropTypes.bool,
};

const styles = theme => ({
  root: {
    zIndex: 9,
  },
  divHideBtnBack: {
    width: '2.142857142857143rem',
    marginRight: 16,
  },
  toolbar: {
    background: '#0e2443',
    minHeight: '50px',
    maxHeight: '50px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    padding: '8px 12px',
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    paddingRight: '48px',
  },
});

export default withStyles(styles)(withRouter(Header));
