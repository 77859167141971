import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import Loading from 'components/Loading';
import DataUser from './components/DataUser';
import { isNullOrEmpty } from 'utils/common';
import lineHelper from 'utils/LineHelper';
import config from 'config';
// import 'utils/VConsole';
import ProfileHeader from 'components/ProfileHeader/ProfileHeader';
import Box from '@material-ui/core/Box';

export class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    try {
      await lineHelper.init(config.line.liff.profile);
      const { member } = this.props;
      await member.loadInfo();
      if (isNullOrEmpty(this.props.member.getInfoJS())) {
        await this.props.member.loadInfo();
      }
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: 'มีข้อผิดพลาดเกิดขึ้น!',
        text: error.message,
      });
    }
  }

  render() {
    const { classes, member } = this.props;
    const memberInfo = member.getInfoJS();
    const profile = member.getProfileJS();
    if (!member.loading && isNullOrEmpty(memberInfo)) {
      this.props.history.push('/consent');
    }
    return (
      <React.Fragment>
        <Container className={classes.homeContainer}>
          {/* <Loading show={member.loading} /> */}
          <ProfileHeader img={profile.pictureUrl} />
          <div
            className={classes.card}
            style={{ flex: 0.1, flexDirection: 'column' }}>
            <div className={classes.tittle}>
            <Typography variant="h5" className={classes.header}>
                ข้อมูลส่วนตัว
                {/* #0f2443 */}
              </Typography>
            </div>
            <DataUser title="ชื่อ-นามสกุล" data={memberInfo.name} />
            <DataUser title="เบอร์โทรศัพท์มือถือ" data={memberInfo.mobile_no} />
            <DataUser title="รหัสพนักงาน" data={memberInfo.employee_no} />
            <DataUser title="UL Code" data={memberInfo.ul_code} />
            <DataUser title="บริษัทที่สังกัด" data={memberInfo.office_name} />
          </div>
          <Box display="flex" justifyContent="center" m={1} p={1}>
            <Button
              variant="contained"
              className={classes.buttonEdit}
              onClick={() => this.props.history.push('/edit-profile')}>
              <Typography variant="h5">แก้ไขข้อมูล</Typography>
            </Button>
          </Box>
          {/* <footer className={classes.footer}>
            <Button
              className={classes.stickToBottom}
              onClick={() => this.props.history.push('/edit-profile')}>
              <Typography variant="h5">แก้ไขข้อมูลส่วนตัว</Typography>
            </Button>
          </footer> */}
          {/* <div className={classes.footer}> */}
          {/* <Button variant="contained" className={classes.listButtom}>
              แก้ไข
            </Button>
          </div> */}
        </Container>
      </React.Fragment>
    );
  }
}

const styles = () => ({
  card: {
    width: 'calc(100vw - 32px)',
    height: 'calc(115vw)',
    position: 'inherit',
    borderRadius: '10px',
    padding: '25px 25px 60px 25px',
    top: '125px',
    border: '2px solid  #DEE2E9',
    backgroundColor: '#DEE2E9',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#204889ff',
    // marginTop: '1rem',
    // marginBottom: '1rem',
  },
  tittle: {
    color: '#0f2443',
    width: '100%',
    textAlign: 'center',
  },
  listButtom: {
    backgroundColor: '#fff',
    width: '40%',
  },
  footer: {
    backgroundColor: '#0e2443',
    fontSize: '20px',
    color: 'white',
    borderTop: '1px solid #E7E7E7',
    textAlign: 'center',
    padding: '10px',
    position: 'fixed',
    left: '0',
    bottom: '0',
    height: '60px',
    width: '100%',
    // display: 'flex',
    flexDirection: 'row',
  },
  buttonEdit: {
    background: '#0e2443',
    color: 'white',
    width: '50%',
    height: '6%',
    // position: 'absolute',
    textAlign: 'center',
    // marginBottom: '17%',
    boxShadow: '4px 4px #888888a8',
    bottom: 0,
    // left: 0,
    '@media (min-width: 375px) and (min-height: 812px)': {
      // height: 42,
      // marginBottom: '33%',
    },
  },
  stickToBottom: {
    background: '#0e2443',
    color: 'white',
    width: '100%',
    position: 'fixed',
    textAlign: 'center',
    bottom: 0,
    left: 0,
    '@media (min-width: 375px) and (min-height: 812px)': {
      height: 55,
    },
  },
});

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  member: PropTypes.object,
  history: PropTypes.object,
};
export default compose(withStyles(styles), inject('member'), observer)(Profile);
