import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Container, Typography } from '@material-ui/core';

export class DataUser extends Component {
  render() {
    const { classes, title, data } = this.props;
    return (
      <Container className={classes.wrapper}>
        <Typography variant="h6" className={classes.fontBold}>{title || ''}</Typography>
        <Typography variant="h6" className={classes.colorBlue}>{data || ''}</Typography>
      </Container>
    );
  }
}

const styles = () => ({
  wrapper: {
    //borderBottom: '1px solid #c0e7fa',
    padding: '10px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'black',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  colorBlue: {
    color: '#204889',
  },
  // fontBold: {
  //   fontFamily: 'PSLDisplayProBold',
  // },
});

DataUser.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  data: PropTypes.string,
  history: PropTypes.object,
};

export default withStyles(styles)(DataUser);
