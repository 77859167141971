import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Button from '@material-ui/core/Button';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { twoDigiNumberWithCommas } from 'utils/common';
import { isNullOrEmpty } from 'utils/common';
import Paper from '@material-ui/core/Paper';

export class DialogConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: 'ยังไม่ได้เลือกไฟล์ใด',
      file: null,
      prescriptionUrl: null,
      textWarning: '',
    };
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  handleClose = () => {
    return this.props.drug.setOpenConfirmDialog(false);
  };

  handleFileUpload = e => {
    this.setState({ loading: true });
    const attratFile = e.target.files[0];
    if (
      (e.target.files &&
        e.target.files.length > 0 &&
        attratFile.type === 'image/png') ||
      attratFile.type === 'image/jpeg' ||
      attratFile.type === 'application/pdf'
    ) {
      let reader = new FileReader();
      let file = e.target.files[0];
      console.log('file', file);
      reader.onloadend = () => {
        console.log('reader', reader.result);
        this.setState({
          file: file,
          prescriptionUrl: reader.result
        });
      };
      reader.readAsDataURL(file);

      this.setState({
        fileName: attratFile.name,
        textWarning: '',
        loading: false,
      });
    } else {
      this.setState({
        fileName:
          'ไฟล์ที่อัพโหลดไม่ถูกต้อง กรุณาอัพไฟล์ .jpeg , .pdf หรือ .png เท่านั้น',
        loading: false,
      });
    }
  };

  confirm = async () => {
    // this.props.drug.setConfirmSubmit(true);
    // this.props.drug.setOpenConfirmDialog(false);
    const { drug } = this.props;
    const drugSelect = drug.getselectList();
    console.log('drugSelect', drugSelect);
    try {
      const prescriptionData = {
        prescriptionUrl: this.state.prescriptionUrl,
      };    
      if (isNullOrEmpty(prescriptionData.prescriptionUrl)) {
        this.setState({
          textWarning: 'คุณยังไม่ได้อัพโหลดใบสั่งยา กรุณาอัพโหลดไฟล์!',
          loading: false,
        });
      } else {
        console.log('success');
        drug.setDrugList([]);
        drug.resetSelectList();
        this.props.history.push('/success');        
        await drug.burnWelfareDrugs(drugSelect);  
        drug.attratPrescription(prescriptionData);
    
      } 
    } catch (err) {
      console.log('err', err);
    }
    return;
  };

  render() {
    const { classes, drug } = this.props;
    const drugSelect = drug.getselectList();
    const drugList = drugSelect.drugList;
    const sumPrice = drugSelect.sumPrice;
    return (
      <Dialog
        open={drug.getOpenConfirmDialog()}
        onClose={this.handleClose}
        className={classes.root}>
        <DialogTitle>
          <Typography variant="h5" align="center">
            สรุปรายการที่เลือก
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Paper variant="outlined" elevation={1}>
            <TableContainer>
              <Table stickyHeader className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.column}>ชื่อยา </TableCell>
                    <TableCell align="right" className={classes.column}>
                      จำนวน
                    </TableCell>
                    <TableCell align="right" className={classes.column}>
                      ราคา
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {drugList.map((drug, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.column}>
                          {`${drug.common_size_name} `}
                          {drug.supply ? `(${drug.supply})` : ''}
                        </TableCell>
                        <TableCell align="right" className={classes.column}>
                          {drug.amount}
                        </TableCell>
                        <TableCell align="right" className={classes.column}>
                          {twoDigiNumberWithCommas(drug.price)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Typography align="right" variant="h6">
            ยอดตัดสวัสดิการ {twoDigiNumberWithCommas(sumPrice)} บาท
          </Typography>
          <div className={classes.attratFileBG}>
            <Button
              color="rose"
              component="label"
              className={classes.attratFileSize}>
              <Typography>
                อัพโหลดใบสั่งยา{' '}
                <AttachFileIcon className={classes.attachFileIcon} />
              </Typography>
              <input
                id="file-input"
                type="file"
                onChange={this.handleFileUpload.bind(this)}
                style={{ display: 'none' }}
              />
            </Button>
          </div>
          <div>{this.state.fileName}</div>
          <div className={classes.textWarning}>{this.state.textWarning}</div>

          <div className={classes.button}>
            <Button
              className={classes.colorRed}
              onClick={this.handleClose}
              variant="contained"
              color="primary">
              ยกเลิก
            </Button>
            <Button
              className={classes.colorGreen}
              onClick={this.confirm}
              variant="contained"
              color="primary">
              ยืนยันตัดสวัสดิการ
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = () => ({
  attratFileBG: {
    backgroundColor: '#0e2443',
    borderRadius: '8px',
    width: '65%',
    padding: '0 10px',
    margin: '0',
  },
  attratFileSize: {
    color: '#fff',
    width: '100%',
    fontSize: '100%',
    padding: '0 16px',
    margin: '0',
  },
  attratFileFontSizeTitle: {
    fontSize: '65%',
  },
  attachFileIcon: {
    width: '1rem',
    height: '1rem',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 20px 20px 20px',
    marginBottom: '20px',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    width: '100%',
    justifyContent: 'space-around',
    paddingTop: '10px',
  },
  colorRed: {
    backgroundColor: '#f44336',
    fontSize: '110%',
  },
  colorGreen: {
    backgroundColor: '#4caf50',
    fontSize: '110%',
  },
  table: {
    width: '100%',
  },
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: '8px',
    },

    '& .MuiDialog-paper': {
      zIndex: '1',
    },
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '& .MuiTableCell-stickyHeader:last-of-type': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      borderTopRightRadius: '8px',
    },
    '& .MuiTableCell-stickyHeader:first-of-type': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      borderTopLeftRadius: '8px',
    },
    '& .MuiTableRow-head': {
      borderRadius: '8px',
    },
    '& tr:last-child > td': {
      borderBottom: '0',
    },
    '& tr:last-child > th': {
      borderBottom: '0',
    },
  },
  column: {
    //fontSize: '1.7rem',
    lineHeight: 1,
  },
  swal_zIndex: {
    zIndex: '1000',
  },
  textWarning: {
    color: 'red',
    margin: 0 
  },

});

DialogConfirm.propTypes = {
  classes: PropTypes.object.isRequired,
  drug: PropTypes.object,
  member: PropTypes.object,
  history: PropTypes.object,
};
export default compose(
  withStyles(styles),
  inject('drug'),
  observer,
)(DialogConfirm);
