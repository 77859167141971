import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/styles';
import { Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import InputBase from '@material-ui/core/InputBase';
import DrugCard from './components/DrugCard/DrugCard';
import { isNullOrEmpty } from 'utils/common';
import lineHelper from 'utils/LineHelper';
import Profile from 'components/ProfileHeader/ProfileHeader';
import config from 'config';
import { twoDigiNumberWithCommas } from 'utils/common';

export class DrugList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      pictureUrl: '',
      textWarning: '',
      resultEmpty: false,
    };
  }

  async componentDidMount() {
    try {
      await lineHelper.init(config.line.liff.drugsList);
      const { member } = this.props;
      await member.loadInfo();
      const memberInfo = member.getInfoJS();
      const profile = member.getProfileJS();
      this.setState({ pictureUrl: profile.pictureUrl });
      // console.log('memberInfo', memberInfo);
      if (isNullOrEmpty(memberInfo)) {
        this.props.history.push('/consent');
      }
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: 'มีข้อผิดพลาดเกิดขึ้น!',
        text: error.message,
      });
    }
  }


  searchDrugList = async () => {
    const { classes,drug } = this.props;
    const { search } = this.state;
    if (search.length === 0) {
      drug.setDrugList([]);
      this.setState({
        resultEmpty: true,
        textWarning: 'กรุณาระบุชื่อยาที่ต้องการค้นหา',
      });
    } else {
      await drug.searchDrugList(search);
      const drugResult = drug.getDrugListJS();
      isNullOrEmpty(drugResult)
        ? this.setState({
          resultEmpty: true,
          textWarning: (<div>ขออภัยค่ะ ไม่เจอยาที่ค้นหา<br />สามารถตรวจสอบยาที่ไม่สามารถจำหน่ายได้ <a href='https://timeline.line.me/post/_dTnXH3rNHUGCIiGEEVER5ImqiOok3KR9O9rzDVQ/1160675460208025411'>ที่นี่</a><br />
          หรือกดแชทคุยกับเราได้เลยค่ะ <br/><br/>
          <Button            
            type="button"
            className={classes.buttonChat}
            onClick={()=>lineHelper.closeWindow()}
          >
            แชท
          </Button>
          </div>
          ),
        })
        : this.setState({ resultEmpty: false, textWarning: '' });
    }
  };

  searchChange = e => {
    let search = e.target.value;
    this.setState({ search }, () => { });
    if (e.key === 'Enter') {
      this.searchDrugList();
    }
  };

  render() {
    const { classes, drug } = this.props;
    const drugResult = drug.getDrugListJS();
    console.log('drugResult', drugResult); // รายการยา ไป map กับ component
    return (
      <React.Fragment>
        <Container className={classes.homeContainer}>
          {/* <Loading show={this.state.loading || member.loading} /> */}
          <Profile img={this.state.pictureUrl} />
          <div
            className={classes.card}
            style={{ flex: 0.1, flexDirection: 'column', marginBottom: '3em' }}>
            <div className={classes.tittle}>
              <Typography variant="h6">
                รายการยาสวัสดิการ
                {/* #0f2443 */}
              </Typography>
            </div>
            <div className={classes.search}>
              <InputBase
                className={classes.input}
                id="outlined-helperText"
                // defaultValue=""
                placeholder="ค้นหา"
                value={this.state.search}
                variant="outlined"
                onChange={this.searchChange.bind(this)}
                onKeyPress={this.searchChange.bind(this)}
              />
              <Button onClick={() => this.searchDrugList()}>
                <SearchRoundedIcon className={classes.searchIcon} />
              </Button>
            </div>
            {drugResult.map((drug, i) => {
              return <DrugCard key={i} data={drug}></DrugCard>;
            })}
            {this.state.resultEmpty ? (
              <div
                className={classes.textWarning}
                container
                direction="row"
                justify="flex-start">
                {this.state.textWarning}
              </div>
            ) : (
                ''
              )}
          </div>
          <div className={classes.footer}>
            <Grid container className={classes.stickyFooter}>
              <Grid item xs={5} align="left">
                <Typography variant="body1" style={{ lineHeight: '1' }}>
                  {' '}
                  รวมตัดสวัสดิการ
                  <br />
                  {twoDigiNumberWithCommas(drug.getselectList().sumPrice)} บาท
                </Typography>
              </Grid>
              <Grid
                className={classes.listButtom}
              //  item xs={5}  align="end"
              >
                <Button
                  className={classes.colorWhite}
                  variant="contained"
                  onClick={() => this.props.history.push('/confirm-order')}>
                  รายการที่เลือก
                </Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const styles = () => ({
  card: {
    // overflow: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
  },
  buttonChat: {
    backgroundColor: '#4caf50',
    fontSize: '110%',
    color: '#fff',
    textAlign: 'center',
    width: '100%',
  },
  tittle: {
    color: '#0f2443',
    width: '100%',
    textAlign: 'center',
  },
  search: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    justifyContent: 'space-between',
    height: '36px',
    lineHeight: '36px',
    padding: '8px',
    fontSize: '13px',
    borderRadius: '18px',
    border: '1px solid',
    overflow: 'hidden',
  },
  searchIcon: {
    margin: 0,
    height: '22px',
  },
  input: {
    padding: '0 12px 0 8px',
    height: '36px',
    border: 'none',
    width: '100%',
    background: 'transparent',
  },
  colorWhite: {
    backgroundColor: '#fff',
    padding: '3px 12px',
  },
  listButtom: {
    margin: '4px 10px',
    position: 'absolute',
    right: '0%',
    '@media (min-width: 375px) and (min-height: 812px)': {
      margin: '5px 10px',
    },
  },
  textWarning: { fontSize: 'larger', marginLeft: '15px', color: 'red', fontFamily: 'PSLDisplayPro, sans-serif', margin: 0 },
  stickyFooter: {
    width: '100%',
    margin: '-2px 0px',
    height: '7%',
    // position: 'absolute',
    '@media (min-width: 375px) and (min-height: 812px)': {
      // height: 110,
    },
  },
  footer: {
    backgroundColor: '#0e2443',
    fontSize: '20px',
    color: 'white',
    textAlign: 'center',
    padding: '5px 10px',
    position: 'absolute',
    zIndex: 9,
    bottom: '4%',
    height: '55px',
    minHeight: '55px',
    width: '92%',
    borderRadius: '5px',
    // flexDirection: 'row',
    // display: 'flex',
    '@media (min-width: 375px) and (min-height: 812px)': {
      height: '55px',
      minHeight: '55px',
      // bottom: '10%',
    },
  },
});

DrugList.propTypes = {
  classes: PropTypes.object.isRequired,
  drug: PropTypes.object,
  member: PropTypes.object,
  history: PropTypes.object,
};

export default compose(
  withStyles(styles),
  inject('drug', 'member'),
  observer,
)(DrugList);
