import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Typography, Button, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import config from 'config';
import lineHelper from 'utils/LineHelper';
import { isNullOrEmpty } from 'utils/common';
// import ButtonFab from 'components/ButtonFab';
// import Condition from 'components/Condition';
import Swal from 'sweetalert2';

export class Consent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkBox: false,
      styleDisabled: '#cecece',
      disabledButton: true,
    };
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    member: PropTypes.object.isRequired,
    history: PropTypes.object,
  };

  async componentDidMount() {
    try {
      await lineHelper.init(config.line.liff.consent);
      const memberInfo = this.props.member.getInfoJS();
      if (isNullOrEmpty(memberInfo)) {
        await this.props.member.loadInfo();
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        type: 'error',
        title: 'มีข้อผิดพลาดเกิดขึ้น!',
        text: error.message,
      });
    }
  }

  clickGoTo(page) {
    // window.location.href = page;
    this.props.history.push(page);
  }

  handleCheckbox = () => {
    if (this.state.checkBox === false) {
      this.setState({
        checkBox: true,
        styleDisabled: '#0e2443',
        disabledButton: false,
      });
    } else {
      this.setState({
        checkBox: false,
        styleDisabled: '#cecece',
        disabledButton: true,
      });
    }
  };

  render() {
    const { member, classes, history } = this.props;
    const { checkBox, styleDisabled, disabledButton } = this.state;
    const memberInfo = member.getInfoJS();
    if (!member.loading && !isNullOrEmpty(memberInfo)) {
      history.push('/drugs-list');
    }
    // const gotoAddtion = () => {
    //   history.push('/conditions-additions');
    // }
    return (
      <div className={classes.wrapperPage}>
        {/* <Banner urlImg={imgHeader} /> */}
        {/* <BannerHeader /> */}
        {/* <div className={classes.wrapperconditions}> */}
        {/* <Condition></Condition> */}
        {/* </div> */}
        <Typography variant="h6" align="center">
          เงื่อนไขและข้อตกลง
          {/* <Link href="#" onClick={gotoAddtion}>คลิกดูรายละเอียดเพิ่มเติม</Link> */}
        </Typography>
        <Typography variant="body1" align="center">
          <div className={classes.boxConsent}>
            <span className="txt">
              {' '}
              ข้าพเจ้ายินยอมตาม
              {/* <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.google.com"> */}
              เงื่อนไขโครงการ สวัสดิการใบสั่งยา
              {/* </a>{' '} */}
              ในการเก็บรวบรวม ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลที่บริษัท ออลล์
              เวลเนส จำกัด และ บริษัท แมสเซนเจอร์ ครีเอชั่น จำกัด
            </span>
            <br />
            <label className={classes.checkBox}>
              <input
                className="input-file"
                type="checkbox"
                id="accept-terms"
                checked={checkBox}
                onClick={this.handleCheckbox}
              />

              <span className="txt">
                ยอมรับ
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://timeline.line.me/post/_dTnXH3rNHUGCIiGEEVER5ImqiOok3KR9O9rzDVQ/1160675421308025090">
                  เงื่อนไขและข้อตกลง
                </a>
              </span>
            </label>
          </div>
        </Typography>
        <Box display="flex" justifyContent="center" p={1}>
          <Button
            style={{
              backgroundColor: styleDisabled,
              color: 'white',
            }}
            variant="contained"
            className={classes.buttonEdit}
            onClick={() => this.clickGoTo('/register')}
            disabled={disabledButton}>
            <Typography variant="h5">ยอมรับ</Typography>
          </Button>
        </Box>
        {/* <ButtonFab
          
          onClick={() => this.clickGoTo('/register')}
          disabled={disabledButton}>
          <Typography variant="h6" className={classes.btn}>
            ยอมรับ
          </Typography>
        </ButtonFab> */}
      </div>
    );
  }
}

const styles = () => ({
  root: {},
  wrapper: {
    margin: '30px auto',
  },
  txtBold: {
    fontFamily: 'PSLDisplayProBold',
  },
  wrapperconditions: {
    padding: '0 20px',
    fontSize: 'larger',
    height: '60%',
  },
  btn: {
    lineHeight: 1.2,
  },
  buttonEdit: {
    background: '#0f2443',
    color: 'white',
    width: '50%',
    height: '6%',
    margin: 0,
    // position: 'absolute',
    textAlign: 'center',
    // marginBottom: '17%',
    boxShadow: '4px 4px #888888a8',
    bottom: 0,
    // left: 0,
    '@media (min-width: 375px) and (min-height: 812px)': {
      // height: 42,
      // marginBottom: '33%',
    },
  },
  textLine: {
    textDecoration: 'underline',
  },
  list: {
    margin: 0,
    listStyle: 'none',
    '& > li': {
      position: 'relative',
      '&:before': {
        content: '"-"',
        display: 'block',
        position: 'absolute',
        left: '-0.6em',
      },
    },
  },
  orderlist: {
    margin: 0,
    listStyle: 'none',
    '& > li': {
      position: 'relative',
      '&:before': {
        display: 'block',
        position: 'absolute',
        left: '-0.6em',
      },
    },
  },
  wrapperPage: {
    // height: '100%'
  },
  boxConsent: {
    width: '85%',
    margin: '1em',
    marginTop: '0em',
    fontSize: 'x-large'
  },
});

export default compose(withStyles(styles), inject('member'), observer)(Consent);
