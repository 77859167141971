import { extendObservable, toJS } from 'mobx';
import axios from 'axios';
import config from 'config';
import lineHelper from 'utils/LineHelper';
import { isNullOrEmpty } from 'utils/common';
// import * as moment from 'moment';

export class MemberStore {
  constructor() {
    extendObservable(this, {
      loading: true,
      profile: {},
      info: {},
      historyWelfare: [],
    });
  }

  getProfileJS() {
    return toJS(this.profile);
  }

  setProfile(profile) {
    this.profile = profile;
  }

  getInfoJS() {
    return toJS(this.info);
  }

  setInfo(info) {
    this.info = info;
  }

  getHistoryWelfareJS() {
    return toJS(this.historyWelfare);
  }

  setHistortWelfare(historyWelfare) {
    this.historyWelfare = historyWelfare;
  }

  async loadInfo() {
    this.loading = true;
    let profile = this.getProfileJS();
    if (isNullOrEmpty(profile)) {
      const lineProfile = await lineHelper.getProfile();
      profile = lineProfile;
      this.setProfile(lineProfile);
    }
    try {
      const res = await axios.get(
        `${config.api.url}/member/line/${profile.userId}`,
      );
      if (res && res.data) {
        const info = res.data;
        this.setInfo(info);
      }
    } catch (error) {
      throw new Error('ไม่สามารถโหลดข้อมูลสมาชิกได้');
    } finally {
      this.loading = false;
    }
  }

  async loadHistoryWelfare() {
    this.loading = true;
    let profile = this.getProfileJS();
    if (isNullOrEmpty(profile)) {
      const lineProfile = await lineHelper.getProfile();
      profile = lineProfile;
      this.setProfile(lineProfile);
    }
    try {
      const res = await axios.get(
        `${config.api.url}/member/history-welfare/${profile.userId}`,
      );
      if (res && res.data) {
        const info = res.data;
        this.setHistortWelfare(info);
      }
    } catch (error) {
      throw new Error('ไม่สามารถโหลดข้อมูลสมาชิกได้');
    } finally {
      this.loading = false;
    }
  }

  async registerMember(data) {
    this.loading = true;
    let profile = this.getProfileJS();
    if (isNullOrEmpty(profile)) {
      const lineProfile = await lineHelper.getProfile();
      profile = lineProfile;
      this.setProfile(lineProfile);
    }
    try {
      const memberInfo = {
        line_user_id: profile.userId,
        name: data.name,
        mobile_no: data.mobile_no,
        employee_no: data.employee_no,
        ul_code: data.ul_code,
        office_name: data.office_name,
      };
      const headers = { 'Content-Type': 'application/json' };
      const res = await axios.post(
        `${config.api.url}/member/register`,
        memberInfo,
        {
          headers,
        },
      );
      if (res && res.data && res.data.message) {
        return res.data.message;
      }
    } catch (error) {
      return error.response && error.response.data
        ? error.response.data.message
        : error.message;
    } finally {
      this.loading = false;
    }
  }

  async updateMember(data) {
    this.loading = true;
    let profile = this.getProfileJS();
    if (isNullOrEmpty(profile)) {
      const lineProfile = await lineHelper.getProfile();
      profile = lineProfile;
      this.setProfile(lineProfile);
    }
    try {
      const memberInfo = {
        line_user_id: profile.userId,
        name: data.name,
        mobile_no: data.mobile_no,
        employee_no: data.employee_no,
        ul_code: data.ul_code,
        office_name: data.office_name,
      };
      const headers = { 'Content-Type': 'application/json' };
      const res = await axios.put(
        `${config.api.url}/member/update`,
        memberInfo,
        {
          headers,
        },
      );
      if (res && res.data) {
        return res.data;
      }
    } catch (error) {
      return error.response && error.response.data
        ? error.response.data.message
        : error.message;
    } finally {
      this.loading = false;
    }
  }
}

const memberStore = new MemberStore();
export default memberStore;
