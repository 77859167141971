import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { twoDigiNumberWithCommas } from 'utils/common';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiSnackbar: {
      // Some CSS
      anchorOriginTopCenter: {
        top: '10em',
        zIndex: '99',
      },
    },
    MuiAlert: {
      // Some CSS
      root: {
        fontFamily: 'PSLDisplayPro, sans-serif',
        fontWeight: 400,
        fontSize: '1em',
      },
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class OrderCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      buttonClick: '',
    };
  }

  componentDidMount() {
    this.setState({ open: false });
  }
  removeDrug = () => {
    console.log(' RemoveDrug work!');

    this.setState({
      open: true,
      buttonClick: 'removedrugs',
    });
  };

  addButton = () => {
    const { drug, data } = this.props;
    this.setState({
      open: true,
      buttonClick: 'add',
    });
    return drug.setSelectList(data);
  };

  removeButton = () => {
    const { drug, data } = this.props;
    if (data.amount === 1) {
      this.setState({ open: true, buttonClick: 'removedrugs' });
      return;
    }
    this.setState({
      open: true,
      buttonClick: 'remove',
    });
    return drug.removeSelectList(data);
  };

  handleClose = (event, reason) => {
    console.log(reason);
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      open: false,
    });
    const { drug, data } = this.props;
    console.log(this.state.buttonClick);
    if (this.state.buttonClick === 'removedrugs') return drug.RemoveDrug(data);
  };

  render() {
    const { classes, data } = this.props;
    console.log('data', data);
    return (
      <div style={{ marginTop: '0.4em' }}>
        <Card className={classes.item}>
          <CardContent
            className={classes.CardContent}
            style={{
              paddingBottom: '10px',
              maxWidth: '100%',
              overflowWrap: 'break-word',
            }}>
            <div className={classes.flex}>
              <Typography
                style={{
                  lineHeight: '0.9',
                  overflowWrap: 'any-where',
                }}
                variant="h6">
                {`${data.drug_name}`}
              </Typography>
              {data.supply && (
                <Typography
                  style={{
                    lineHeight: '0.9',
                    overflowWrap: 'any-where',
                  }}
                  variant="h6">
                  {`(${data.supply})`}
                </Typography>
              )}
            </div>
            <Typography
              style={{
                lineHeight: '0.9',
                overflowWrap: 'any-where',
              }}
              variant="h6">
              {` `}{`${data.common_size_name}`}
            </Typography>
            <div className={classes.flexContainer}>
              <div className={classes.flexitem}>
                <Typography variant="caption" display="block" gutterBottom>
                  <button
                    className={classes.ButtonStyle}
                    onClick={this.removeButton}>
                    -
                  </button>
                  <button
                    className={classes.ButtonStyle}>{`${data.amount}`}</button>
                  <button
                    className={classes.ButtonStyle}
                    onClick={this.addButton}>
                    +
                  </button>
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <p className={classes.price}>
                  ฿{twoDigiNumberWithCommas(data.price)}
                  {/* emp_price_vat */}
                </p>
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  className={classes.largeIconDelete}
                  onClick={() => this.removeDrug()}>
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
                <ThemeProvider theme={theme}>
                  <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={this.state.open}
                    autoHideDuration={1500}
                    onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity="success">
                      {(this.state.buttonClick === 'removedrugs' ||
                        this.state.buttonClick === 'remove') &&
                        'ลบรายการยาสำเร็จแล้ว'}
                      {this.state.buttonClick === 'add' &&
                        'เพิ่มรายการยาสำเร็จแล้ว'}
                    </Alert>
                  </Snackbar>
                </ThemeProvider>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}
const styles = () => ({
  card: {
    width: 'calc(100vw - 32px)',
    minHeight: 'calc(100vh - 100px)',
    position: 'absolute',
    borderRadius: '25px',
    padding: '25px 25px 60px 25px',
    top: '100px',
    border: '2px solid  #ddd',
    backgroundColor: '#ddd',
    display: 'flex',
    flexDirection: 'column',
  },
  CardContent: {
    flex: '1',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: '25px',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    padding: '1px',
    flexDirection: 'row',
  },
  item: {
    minWidth: 'calc(100vw - 80px)',
    position: 'relative',
    display: 'flex',
    borderRadius: '5px',
  },
  flex: {
    display: 'flex',
  },
  shopIcon: {
    color: '#48752f',
    // position: 'absolute',
    // right: '20px',
    // top: '15px',
  },
  ButtonStyle: {
    color: 'Blue',
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: '1em',
  },
  spacearound: {
    justifycontent: 'spacearound',
  },
  price: {
    // bottom: '-1.6rem',
    color: 'blue',
    paddingRight: '10px',
    // right: '1.7em',
    // position: 'absolute',
    fontSize: '1.8rem',
    textAlign: 'right',
    margin: '0',
  },
});

OrderCard.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  drug: PropTypes.object,
};

export default compose(withStyles(styles), inject('drug'), observer)(OrderCard);
