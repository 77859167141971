import { extendObservable, toJS } from 'mobx';
import axios from 'axios';
import config from 'config';
import lineHelper from 'utils/LineHelper';
import { isNullOrEmpty } from 'utils/common';
// import * as moment from 'moment';

export class DrugStore {
  constructor() {
    extendObservable(this, {
      loading: true,
      drugList: [],
      profile: {},
      selectList: {
        sumPrice: 0,
        sumEmpPrice: 0,
        sumCostPrice: 0,
        sumCostPriceVat: 0,
        drugList: [],
      },
      orderHistoryDetail: {},
      openConfirmDialog: false,
      confirmSubmit: false,
      orderId:'',
      prescriptionUrl:'',
    });
    // this.init();
  }

  init() {
    // let drugsListStorage = localStorage.getItem('cart_item');
    // drugsListStorage = JSON.parse(drugsListStorage);
    // console.log('drugsListStorage', drugsListStorage);
    // this.selectList = drugsListStorage;
  }
  resetSelectList() {
    this.selectList = {
      sumPrice: 0,
      sumEmpPrice: 0,
      sumCostPrice: 0,
      sumCostPriceVat: 0,
      drugList: [],
    };
  }

  getOpenConfirmDialog() {
    return toJS(this.openConfirmDialog);
  }

  setOpenConfirmDialog(isOpen) {
    this.openConfirmDialog = isOpen;
  }

  getConfirmSubmit() {
    return toJS(this.confirmSubmit);
  }

  setConfirmSubmit(isConfirm) {
    this.confirmSubmit = isConfirm;
  }

  getOrderHistoryDetailJS() {
    return toJS(this.orderHistoryDetail);
  }

  setOrderHistoryDetail(orderHistoryDetail) {
    this.orderHistoryDetail = orderHistoryDetail;
  }

  getProfileJS() {
    return toJS(this.profile);
  }

  setProfile(profile) {
    this.profile = profile;
  }

  getselectList() {
    return toJS(this.selectList);
  }
  setSelectList(data) {
    // console.log(data);
    const index = this.selectList.drugList.findIndex(
      element => element._id === data._id,
    );
    if (index >= 0) {
      this.selectList.drugList[index].amount += 1;
      this.selectList.sumPrice =
        Math.round(
          (this.selectList.sumPrice + this.selectList.drugList[index].price) *
            1e12,
        ) / 1e12;
      this.selectList.sumEmpPrice =
        Math.round(
          (this.selectList.sumEmpPrice +
            this.selectList.drugList[index].emp_price) *
            1e12,
        ) / 1e12;
      this.selectList.sumCostPrice =
        Math.round(
          (this.selectList.sumCostPrice +
            this.selectList.drugList[index].cost_price) *
            1e12,
        ) / 1e12;
      this.selectList.sumCostPriceVat =
        Math.round(
          (this.selectList.sumCostPriceVat +
            this.selectList.drugList[index].cost_price_vat) *
            1e12,
        ) / 1e12;
    } else {
      this.selectList.drugList.push({
        _id: data._id,
        amount: 1,
        common_name: data.common_name,
        common_size_name: data.common_size_name,
        drug_name: data.drug_name,
        size: data.size,
        unit: data.unit,
        unit_name: data.unit_name,
        price: data.emp_price_vat, //emp_price_vat
        emp_price: data.emp_price,
        cost_price: data.cost_price,
        cost_price_vat: data.cost_price_vat,
        supply: data.supply ? data.supply : '',
      });
      this.selectList.sumPrice += data.emp_price_vat; //emp_price_vat
      this.selectList.sumEmpPrice += data.emp_price; //emp_price
      this.selectList.sumCostPrice += data.cost_price; //cost_price
      this.selectList.sumCostPriceVat += data.cost_price_vat; //cost_price_vat
    }
    let storage = JSON.stringify(this.selectList);
    localStorage.setItem('cart_item', storage);
  }
  // when subtract a drug in a list
  removeSelectList(data) {
    const index = this.selectList.drugList.findIndex(
      element => element._id === data._id,
    );
    if (index >= 0) {
      this.selectList.drugList[index].amount -= 1;
      this.selectList.sumPrice =
        Math.round(
          (this.selectList.sumPrice - this.selectList.drugList[index].price) *
            1e12,
        ) / 1e12;
      this.selectList.sumEmpPrice =
        Math.round(
          (this.selectList.sumEmpPrice -
            this.selectList.drugList[index].emp_price) *
            1e12,
        ) / 1e12;
      this.selectList.sumCostPrice =
        Math.round(
          (this.selectList.sumCostPrice -
            this.selectList.drugList[index].cost_price) *
            1e12,
        ) / 1e12;
      this.selectList.sumCostPriceVat =
        Math.round(
          (this.selectList.sumCostPriceVat -
            this.selectList.drugList[index].cost_price_vat) *
            1e12,
        ) / 1e12;
      if (this.selectList.drugList[index].amount === 0) {
        this.selectList.drugList.splice(index, 1);
      }
    }
  }

  RemoveDrug(data) {
    const index = this.selectList.drugList.findIndex(
      element => element._id === data._id,
    );
    if (index > -1) {
      this.selectList.sumPrice =
        Math.round(
          (this.selectList.sumPrice -
            this.selectList.drugList[index].price *
              this.selectList.drugList[index].amount) *
            1e12,
        ) / 1e12;
      this.selectList.sumEmpPrice =
        Math.round(
          (this.selectList.sumEmpPrice -
            this.selectList.drugList[index].emp_price *
              this.selectList.drugList[index].amount) *
            1e12,
        ) / 1e12;
      this.selectList.sumCostPrice =
        Math.round(
          (this.selectList.sumCostPrice -
            this.selectList.drugList[index].cost_price *
              this.selectList.drugList[index].amount) *
            1e12,
        ) / 1e12;
      this.selectList.sumCostPriceVat =
        Math.round(
          (this.selectList.sumCostPriceVat -
            this.selectList.drugList[index].cost_price_vat *
              this.selectList.drugList[index].amount) *
            1e12,
        ) / 1e12;

      this.selectList.drugList.splice(index, 1);
    }
  }
  getDrugListJS() {
    return toJS(this.drugList);
  }

  setDrugList(_drugList) {
    this.drugList = _drugList;
  }

  async searchDrugList(search) {
    this.loading = true;
    try {
      const res = await axios.get(
        `${config.api.url}/drug/search?search=${search}`,
      );
      if (res && res.data) {
        const drug = res.data;
        this.setDrugList(drug);
      }
    } catch (error) {
      throw new Error('เกิดข้อผิดพลาดในการค้นหาจากระบบ');
    } finally {
      this.loading = false;
    }
  }

  async burnWelfareDrugs(data) {
    this.loading = true;
    let profile = this.getProfileJS();
    if (isNullOrEmpty(profile)) {
      const lineProfile = await lineHelper.getProfile();
      profile = lineProfile;
      this.setProfile(lineProfile);
    }
    try {
      const burnWelfareDrugs = {
        line_user_id: profile.userId,
        drugList: data.drugList,
        sumPrice: data.sumPrice,
        sumEmpPrice: data.sumEmpPrice,
        sumCostPrice: data.sumCostPrice,
        sumCostPriceVat: data.sumCostPriceVat,
      };
      const headers = { 'Content-Type': 'application/json' };
      const res = await axios.post(
        `${config.api.url}/drug/burn-welfare`,
        burnWelfareDrugs,
        {
          headers,
        },
      );
      console.log('res.data', res.data);
      this.order_id = res.data.order_id;
      if (res && res.data) {
        return res.data;
      }
    } catch (error) {
      return error.response && error.response.data
        ? error.response.data.message
        : error.message;
    } finally {
      this.loading = false;
    }
  }

  async attratPrescription(prescriptionData) {
    this.loading = true;
    try {
      console.log('prescriptionData',prescriptionData);
      const orderId = this.order_id;
      const prescriptionUrl = prescriptionData.prescriptionUrl;
      this.prescriptionUrl = prescriptionUrl;
      const prescription ={
        orderId,
        prescriptionUrl
      };
      const headers = { 'Content-Type': 'application/json' };
      const url = `${config.api.url}/drug/upload-prescription`;
      const res = await axios.post(url, prescription, { headers });
      console.log('res.dataAttratFileConfirm', res.data);
      if (res && res.data) {
        return res.data;
      }
    } catch (error) {
      return error.response && error.response.data
        ? error.response.data.message
        : error.message;
    } finally {
      this.loading = false;
    }
  }

  getPrescription() {
    return toJS(this.prescriptionUrl);
  }
}

const drugStore = new DrugStore();
export default drugStore;
