const config = {
  env: 'staging',
  api: {
    url: 'https://extaplus-dev.emetworks.tech/api/v1',
  },
  line: {
    liff: {
      register: '1655701869-y5zbjQvJ',
      profile: '1655701869-zKVlqm49',
      drugsList: '1655701869-5jrn9bxL',
      consent: '1655701869-E96yW01w',
    },
  },
};

export default config;
