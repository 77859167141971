const config = {
  env: 'production',
  api: {
    url: 'https://extaplus.emetworks.tech/api/v1',
    // url: 'https://extaplus-api.emetworks.tech/api',
  },
  line: {
    liff: {
      register: '1655299126-Oda5z50K',
      profile: '1655299126-eod5w5mx',
      drugsList: '1655299126-ApKNLN4v',
      consent: '1655299126-o5el4l65',
    },
  },
};

export default config;
