import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Typography, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import { isNullOrEmpty } from 'utils/common';
import lineHelper from 'utils/LineHelper';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import config from 'config';
// import 'utils/VConsole';
// import Loading from 'components/Loading';
// import clsx from 'clsx';
// import * as moment from 'moment';

const RegisterSchema = Yup.object().shape({
  name: Yup.string().required('กรุณากรอกชื่อและนามสกุล'),
  mobile_no: Yup.string()
    .min(10, 'กรุณากรอกเบอร์โทรศัพท์มือถือจำนวน 10 หลักเท่านั้น')
    .max(10, 'กรุณากรอกเบอร์โทรศัพท์มือถือจำนวน 10 หลักเท่านั้น')
    .required('กรุณากรอกเบอร์มือถือ'),
  ul_code: Yup.string()
    .min(1, 'กรุณากรอก UL Code จำนวนไม่เกิน 15 หลักเท่านั้น')
    .max(15, 'กรุณากรอก UL Code จำนวนไม่เกิน 15 หลักเท่านั้น')
    .required('กรุณากรอก UL Code'),
  office_name: Yup.string().required('กรุณากรอกบริษัทที่สังกัด'),
  employee_no: Yup.string().required('กรุณากรอกรหัสพนักงาน'),
});

export class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledButton: true,
      loading: true,
      // register_drug: {
      //   name: '',
      //   mobile_no: '',
      //   employee_no: '',
      //   ul_code: '',
      //   office_name: '',
      // },
    };
  }

  async componentDidMount() {
    try {
      await lineHelper.init(config.line.liff.register);
      const { member } = this.props;
      await member.loadInfo();
      const memberInfo = member.getInfoJS();
      console.log('memberInfo', memberInfo);
      if (!isNullOrEmpty(memberInfo)) {
        this.routeChange();
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  routeChange() {
    this.props.history.push(`/drugs-list`);
  }

  addClassInput = (touched, errors) => {
    const { classes } = this.props;
    let valueClass = '';
    if (touched) {
      if (errors) {
        valueClass = classes.isinvalid;
      } else {
        valueClass = classes.isvalid;
      }
    } else {
      valueClass = '';
    }
    return valueClass;
  };

  addClassSelect = (touched, errors) => {
    const { classes } = this.props;
    let valueClass = '';
    if (touched) {
      if (errors) {
        valueClass = classes.notSelect;
      }
    } else {
      valueClass = '';
    }
    return valueClass;
  };

  onClickRegister = async values => {
    const { member } = this.props;
    const register = await member.registerMember(values);
    if (register === 'success') {
      Swal.fire(
        {
          html:
            '<span style="color:#fff;font-size:1.5em">ลงทะเบียนสำเร็จ</span>',
          // title: '<span style="color:#fff">ลงทะเบียนสำเร็จ</span>',
          timer: 3000,
          showCancelButton: false,
          showConfirmButton: false,
          background: '#0e2443',
        },
        this.routeChange(),
      );
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        {/* <Loading show={this.state.loading || this.props.shop.loading} /> */}
        <div className={classes.wrapperContent}>
          <Typography variant="h5" className={classes.header}>
            ลงทะเบียน
          </Typography>
          <React.Fragment>
            <Formik
              initialValues={{
                name: '',
                employee_no: '',
                ul_code: '',
                mobile_no: '',
                office_name: '',
              }}
              validationSchema={RegisterSchema}
              onSubmit={values => {
                this.onClickRegister(values);
                // console.log('values', values);
              }}>
              {({ errors, touched, setFieldValue, isValid, values }) => (
                <Form>
                  <Fragment>
                    <div className={classes.wrapData}>
                      <Field
                        id="name"
                        name="name"
                        type="text"
                        placeholder="ชื่อ-นามสกุล"
                        className={`${classes.formControl} ${this.addClassInput(
                          touched.name,
                          errors.name,
                        )}`}
                      />
                    </div>
                    {errors.name && touched.name && (
                      <ErrorMessage
                        component="div"
                        name="name"
                        className={classes.invalidFeedback}
                      />
                    )}
                  </Fragment>
                  <Fragment>
                    <div className={classes.wrapData}>
                      <Field
                        id="mobile_no"
                        name="mobile_no"
                        type="text"
                        maxLength="10"
                        pattern="[0-9]*"
                        placeholder="เบอร์โทรศัพท์มือถือ"
                        inputMode="numeric"
                        // onInput={this.handleChange.bind(this)}
                        // value={this.state.telNo}
                        className={`${classes.formControl} ${this.addClassInput(
                          touched.mobile_no,
                          errors.mobile_no,
                        )}`}
                      />
                    </div>
                    {errors.mobile_no && touched.mobile_no && (
                      <ErrorMessage
                        component="div"
                        name="mobile_no"
                        className={classes.invalidFeedback}
                      />
                    )}
                  </Fragment>
                  <Fragment>
                    <div className={classes.wrapData}>
                      <Field
                        id="employee_no"
                        name="employee_no"
                        type="text"
                        placeholder="รหัสพนักงาน"
                        className={`${classes.formControl} ${this.addClassInput(
                          touched.employee_no,
                          errors.employee_no,
                        )}`}
                      />
                    </div>
                    {errors.employee_no && touched.employee_no && (
                      <ErrorMessage
                        component="div"
                        name="employee_no"
                        className={classes.invalidFeedback}
                      />
                    )}
                  </Fragment>
                  <Fragment>
                    <div className={classes.wrapData}>
                      <Field
                        id="ul_code"
                        name="ul_code"
                        type="text"
                        maxLength="15"
                        pattern="\d*"
                        placeholder="UL Code"
                        inputmode="numeric"
                        // onInput={this.handleChange.bind(this)}
                        // value={this.state.telNo}
                        className={`${classes.formControl} ${this.addClassInput(
                          touched.ul_code,
                          errors.ul_code,
                        )}`}
                      />
                    </div>
                    {errors.ul_code && touched.ul_code && (
                      <ErrorMessage
                        component="div"
                        name="ul_code"
                        className={classes.invalidFeedback}
                      />
                    )}
                  </Fragment>
                  <Fragment>
                    <div className={classes.wrapData}>
                      <Field
                        id="office_name"
                        name="office_name"
                        type="text"
                        placeholder="บริษัทที่สังกัด"
                        className={`${classes.formControl} ${this.addClassInput(
                          touched.office_name,
                          errors.office_name,
                        )}`}
                      />
                    </div>
                    {errors.office_name && touched.office_name && (
                      <ErrorMessage
                        component="div"
                        name="office_name"
                        className={classes.invalidFeedback}
                      />
                    )}
                    <Box display="flex" justifyContent="center" m={1} p={1}>
                      <Button
                        variant="contained"
                        className={classes.buttonRegister}
                        type="submit">
                        <Typography variant="h5">ลงทะเบียน</Typography>
                      </Button>
                    </Box>
                  </Fragment>
                  {/* <footer className={classes.footer}>
                    <Button className={classes.stickToBottom} type="submit">
                      <Typography variant="h5">ลงทะเบียน</Typography>
                    </Button>
                  </footer> */}
                </Form>
              )}
            </Formik>
          </React.Fragment>
        </div>
      </Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  textInput: {
    marginBottom: theme.spacing(1),
    borderRadius: '20%',
  },
  invalidFeedback: {
    marginTop: '-12px',
    color: 'red',
  },
  wrapperContent: {
    // background: `rgba(0, 107, 59, 0.4) top right no-repeat`,
    backgroundSize: '100% auto',
    padding: '0 20px',
    height: '100vh',
    width: '100%',
    overflow: 'auto',
    position: 'fixed',
  },
  header: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#204889ff',
    marginTop: '1rem',
    // marginBottom: '1rem',
  },
  input: {
    fontSize: 'inherit',
    width: '85%',
    margin: 'auto',
    border: '2px solid #007934',
    padding: '10px 5px',
    textAlign: 'center',
    display: 'block',
    '&:focus': {
      outline: 'none',
    },
  },
  inputDisabled: {
    fontSize: '1rem',
    width: '100%',
    margin: 'auto',
    marginBottom: '2rem',
    border: '2px solid #007934',
    padding: '10px 5px',
    textAlign: 'center',
    '&:focus': {
      outline: 'none',
    },
  },
  center: {
    textAlign: 'center',
  },
  checkBox: {
    fontSize: '1.4rem',
  },
  footer: {
    zIndex: 10000,
    position: 'fixed',
  },
  buttonRegister: {
    background: '#0e2443',
    color: 'white',
    width: '50%',
    height: 'auto',
    position: 'fixed',
    textAlign: 'center',
    marginTop: '20%',
    boxShadow: '4px 4px #888888a8',
    // bottom: 0,
    // left: 0,
    '@media (min-width: 375px) and (min-height: 812px)': {
      // height: 42,
    },
  },
  stickToBottom: {
    background: '#0e2443',
    color: 'white',
    width: '100%',
    position: 'fixed',
    textAlign: 'center',
    bottom: 0,
    left: 0,
    '@media (min-width: 375px) and (min-height: 812px)': {
      height: 55,
    },
    // '& hover': {
    //   backgroundColor: 'red',
    // },
  },
  wrapData: {
    margin: '10px auto',
  },
  formControl: {
    width: '100%',
    borderRadius: 6,
    fontSize: 'larger',
    lineHeight: 1,
    fontFamily: 'inherit',
    padding: 5,
    border: '1px solid #204889ff',
    '&:focus': {
      outline: 'none',
    },
  },
});

Register.propTypes = {
  classes: PropTypes.object.isRequired,
  member: PropTypes.object,
  history: PropTypes.object,
};

export default compose(
  withStyles(styles),
  inject('member'),
  observer,
)(Register);
