import React from 'react';
import { Switch } from 'react-router-dom';
import RouteWithLayout from 'components/RouteWithLayout';
import MainLayout from 'layouts/MainLayout';
import ProfileLayout from 'layouts/ProfileLayout';
import NotFoundPage from 'pages/NotFound';
import HomePage from 'pages/Home';
import ProfilePage from 'pages/Profile';
import EditProfilePage from 'pages/EditProfile';
import ConfirmOrderPage from 'pages/ConfirmOrder';
import SuccessPage from 'pages/ConfirmOrder/Success/Success';
import WelfareCutHistoryPage from 'pages/WelfareCutHistory';
import DrugList from 'pages/DrugList';
import RegisterPage from 'pages/Register';
import WelfareCutDetailsPage from 'pages/WelfareCutHistory/MoreDetails';
import ConsentPage from 'pages/Consent/Consent';

export default class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <RouteWithLayout
          exact
          path="/"
          component={HomePage}
          layout={MainLayout}
          hiddenBtnBack={true}
          title="eXta Health Center"
        />
        <RouteWithLayout
          exact
          path="/register"
          component={RegisterPage}
          layout={ProfileLayout}
          hiddenBtnBack={false}
          title="eXta Health Center"
        />
        <RouteWithLayout
          exact
          path="/profile"
          component={ProfilePage}
          layout={ProfileLayout}
          hiddenBtnBack={true}
          title="eXta Health Center"
        />
        <RouteWithLayout
          exact
          path="/edit-profile"
          component={EditProfilePage}
          layout={ProfileLayout}
          hiddenBtnBack={true}
          title="eXta Health Center"
        />
        <RouteWithLayout
          exact
          path="/drugs-list"
          component={DrugList}
          layout={ProfileLayout}
          hiddenBtnBack={true}
          title="eXta Health Center"
        />
        <RouteWithLayout
          exact
          path="/confirm-order"
          component={ConfirmOrderPage}
          layout={ProfileLayout}
          hiddenBtnBack={true}
          title="eXta Health Center"
        />
        <RouteWithLayout
          exact
          path="/success"
          component={SuccessPage}
          layout={ProfileLayout}
          hiddenBtnBack={true}
          title="eXta Health Center"
        />
        <RouteWithLayout
          exact
          path="/welfare-cut-details"
          component={WelfareCutDetailsPage}
          layout={ProfileLayout}
          hiddenBtnBack={true}
          title="eXta Health Center"
        />
        <RouteWithLayout
          exact
          path="/welfare-cut-history"
          component={WelfareCutHistoryPage}
          layout={ProfileLayout}
          hiddenBtnBack={true}
          title="eXta Health Center"
        />
        <RouteWithLayout
          exact
          path="/consent"
          component={ConsentPage}
          layout={ProfileLayout}
          hiddenBtnBack={false}
          title="eXta Health Center"
        />
        <RouteWithLayout component={NotFoundPage} layout={MainLayout} />
      </Switch>
    );
  }
}
