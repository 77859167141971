export default {
  root: {
    fontSize: '1.3rem',
    lineHeight: 1,
    '&$selected': {
      color: 'white',
      textShadow:'0 0 0.2em #eb1c24, 0 0 0.2em #eb1c24, 0 0 0.2em #eb1c24',
      // fontFamily: 'PSLDisplayProBold',
      // textShadow: '-2px 0px 0 white, -2px 1px 0 white, -1px -2px 0 white, -1px -1px 0 white, -1px 0px 0 white, -1px 1px 0 white, -1px 2px 0 white, 0px -2px 0 white, 0px -1px 0 white, 0px 0px 0 white, 0px 1px 0 white, 0px 2px 0 white, 1px -2px 0 white, 1px -1px 0 white, 1px 0px 0 white, 1px 1px 0 white, 1px 2px 0 white, 2px -1px 0 white, 2px 0px 0 white, 2px 1px 0 white'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '4px auto',
    color: '#204889',
    letterSpacing: 0,
  },
  textColorInherit: {
    color: 'white',
    textShadow:'0 0 0.2em #204889ff, 0 0 0.2em #204889ff, 0 0 0.2em #204889ff',
    opacity: 1,
  },
};
