import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Typography, Button, Container } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { isNullOrEmpty } from 'utils/common';
// import lineHelper from 'utils/LineHelper';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
// import 'utils/VConsole';
import Profile from 'components/ProfileHeader/ProfileHeader';
import Box from '@material-ui/core/Box';

// import config from 'config';
// import Loading from 'components/Loading';
// import clsx from 'clsx';
// import * as moment from 'moment';

const RegisterSchema = Yup.object().shape({
  name: Yup.string().required('กรุณากรอกชื่อและนามสกุล'),
  mobile_no: Yup.string()
    .min(10, 'กรุณากรอกเบอร์โทรศัพท์มือถือจำนวน 10 หลักเท่านั้น')
    .max(10, 'กรุณากรอกเบอร์โทรศัพท์มือถือจำนวน 10 หลักเท่านั้น')
    .required('กรุณากรอกเบอร์มือถือ'),
  ul_code: Yup.string().required('กรุณากรอก UL Code'),
  office_name: Yup.string().required('กรุณากรอกบริษัทที่สังกัด'),
  employee_no: Yup.string().required('กรุณากรอกรหัสพนักงาน'),
});

export class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledButton: true,
      loading: true,
      name: '',
      mobile_no: '',
      employee_no: '',
      ul_code: '',
      office_name: '',
    };
  }

  async componentDidMount() {
    try {
      if (isNullOrEmpty(this.props.member.getInfoJS())) {
        await this.props.member.loadInfo();
      }
      const member = this.props.member.getInfoJS();
      this.setState({
        name: member.name,
        mobile_no: member.mobile_no,
        employee_no: member.employee_no,
        ul_code: member.ul_code,
        office_name: member.office_name,
      });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  async routeChange() {
    this.props.history.push(`/profile`);
  }

  addClassInput = (touched, errors) => {
    const { classes } = this.props;
    let valueClass = '';
    if (touched) {
      if (errors) {
        valueClass = classes.isinvalid;
      } else {
        valueClass = classes.isvalid;
      }
    } else {
      valueClass = '';
    }
    return valueClass;
  };

  addClassSelect = (touched, errors) => {
    const { classes } = this.props;
    let valueClass = '';
    if (touched) {
      if (errors) {
        valueClass = classes.notSelect;
      }
    } else {
      valueClass = '';
    }
    return valueClass;
  };

  onClickEditMember = async values => {
    const { member } = this.props;
    const update = await member.updateMember(values);
    console.log('update', update);
    if (update.message === 'success') {
      await this.props.member.loadInfo();
      Swal.fire(
        {
          html:
            '<div style="color:#fff;font-size:1.5em">อัพเดตข้อมูลสำเร็จ</div>',
          timer: 3000,
          showCancelButton: false,
          showConfirmButton: false,
          background: '#0e2443',
        },
        this.routeChange(),
      );
    }
  };

  render() {
    const { classes, member } = this.props;
    const { name, mobile_no, employee_no, ul_code, office_name } = this.state;
    const form = {};
    form.name = name;
    form.mobile_no = mobile_no;
    form.employee_no = employee_no;
    form.ul_code = ul_code;
    form.office_name = office_name;
    const memberInfo = member.getInfoJS();
    const profile = member.getProfileJS();
    if (!member.loading && isNullOrEmpty(memberInfo)) {
      this.props.history.push('/register');
    }
    return (
      <React.Fragment>
        <Container className={classes.homeContainer}>
          {/* <Loading show={member.loading} /> */}
          <Profile img={profile.pictureUrl} />
          <div
            className={classes.card}
            style={{ flex: 0.1, flexDirection: 'column' }}>
            <Typography variant="h6" className={classes.header}>
              แก้ไขข้อมูลส่วนตัว
            </Typography>
            <React.Fragment>
              <Formik
                enableReinitialize={true}
                initialValues={form}
                validationSchema={RegisterSchema}
                onSubmit={values => {
                  this.onClickEditMember(values);
                }}>
                {({ errors, touched, setFieldValue, isValid, values }) => (
                  <Form>
                    <Fragment>
                      <div className={classes.wrapData}>
                        <Field
                          id="name"
                          name="name"
                          type="text"
                          placeholder="ชื่อ-นามสกุล"
                          className={`${
                            classes.formControl
                          } ${this.addClassInput(touched.name, errors.name)}`}
                        />
                      </div>
                      {errors.name && touched.name && (
                        <ErrorMessage
                          component="div"
                          name="name"
                          className={classes.invalidFeedback}
                        />
                      )}
                    </Fragment>
                    <Fragment>
                      <div className={classes.wrapData}>
                        <Field
                          id="mobile_no"
                          name="mobile_no"
                          type="text"
                          maxLength="10"
                          pattern="[0-9]*"
                          placeholder="เบอร์โทรศัพท์มือถือ"
                          inputMode="numeric"
                          // onInput={this.handleChange.bind(this)}
                          // value={this.state.telNo}
                          className={`${
                            classes.formControl
                          } ${this.addClassInput(
                            touched.mobile_no,
                            errors.mobile_no,
                          )}`}
                        />
                      </div>
                      {errors.mobile_no && touched.mobile_no && (
                        <ErrorMessage
                          component="div"
                          name="mobile_no"
                          className={classes.invalidFeedback}
                        />
                      )}
                    </Fragment>
                    <Fragment>
                      <div className={classes.wrapData}>
                        <Field
                          id="employee_no"
                          name="employee_no"
                          type="text"
                          placeholder="รหัสพนักงาน"
                          className={`${
                            classes.formControl
                          } ${this.addClassInput(
                            touched.employee_no,
                            errors.employee_no,
                          )}`}
                        />
                      </div>
                      {errors.employee_no && touched.employee_no && (
                        <ErrorMessage
                          component="div"
                          name="employee_no"
                          className={classes.invalidFeedback}
                        />
                      )}
                    </Fragment>
                    <Fragment>
                      <div className={classes.wrapData}>
                        <Field
                          id="ul_code"
                          name="ul_code"
                          type="text"
                          placeholder="UL Code"
                          className={`${
                            classes.formControl
                          } ${this.addClassInput(
                            touched.ul_code,
                            errors.ul_code,
                          )}`}
                        />
                      </div>
                      {errors.ul_code && touched.ul_code && (
                        <ErrorMessage
                          component="div"
                          name="ul_code"
                          className={classes.invalidFeedback}
                        />
                      )}
                    </Fragment>
                    <Fragment>
                      <div className={classes.wrapData}>
                        <Field
                          id="office_name"
                          name="office_name"
                          type="text"
                          placeholder="บริษัทที่สังกัด"
                          className={`${
                            classes.formControl
                          } ${this.addClassInput(
                            touched.office_name,
                            errors.office_name,
                          )}`}
                        />
                      </div>
                      {errors.office_name && touched.office_name && (
                        <ErrorMessage
                          component="div"
                          name="office_name"
                          className={classes.invalidFeedback}
                        />
                      )}
                    </Fragment>
                    <Box
                      className={classes.boxButton}
                      display="flex"
                      justifyContent="center"
                      m={1}
                      p={1}>
                      <Button
                        variant="contained"
                        className={classes.stickToBottom}
                        onClick={() => this.props.history.push('/profile')}>
                        {/* <Typography variant="h5"> */}
                        ยกเลิก
                        {/* </Typography> */}
                      </Button>
                      <Button
                        variant="contained"
                        className={classes.stickToBottom}
                        type="submit">
                        {/* <Typography variant="h5"> */}
                        บันทึก
                        {/* </Typography> */}
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </React.Fragment>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  textInput: {
    marginBottom: theme.spacing(1),
    borderRadius: '20%',
  },
  card: {
    width: 'calc(100vw - 32px)',
    height: 'auto',
    position: 'absolute',
    borderRadius: '10px',
    padding: '25px 25px 60px 25px',
    top: '125px',
    border: '2px solid  #DEE2E9',
    backgroundColor: '#DEE2E9',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapperContent: {
    // background: `rgba(0, 107, 59, 0.4) top right no-repeat`,
    backgroundSize: '100% auto',
    padding: '0 20px',
    height: '100vh',
    width: '100%',
    overflow: 'auto',
    position: 'fixed',
  },
  header: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#204889ff',
    marginTop: '1rem',
    // marginBottom: '1rem',
  },
  boxButton: {
    position: 'relative',
    marginTop: '10%',
  },
  input: {
    fontSize: 'inherit',
    width: '85%',
    margin: 'auto',
    border: '2px solid #007934',
    padding: '10px 5px',
    textAlign: 'center',
    display: 'block',
    '&:focus': {
      outline: 'none',
    },
  },
  inputDisabled: {
    fontSize: '1rem',
    width: '100%',
    margin: 'auto',
    marginBottom: '2rem',
    border: '2px solid #007934',
    padding: '10px 5px',
    textAlign: 'center',
    '&:focus': {
      outline: 'none',
    },
  },
  center: {
    textAlign: 'center',
  },
  checkBox: {
    fontSize: '1.4rem',
  },
  footer: {
    backgroundColor: '#0e2443',
    fontSize: '20px',
    color: 'white',
    borderTop: '1px solid #E7E7E7',
    textAlign: 'center',
    padding: '10px',
    position: 'fixed',
    left: '0',
    bottom: '0',
    height: '60px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  stickToBottom: {
    background: '#0e2443',
    color: '#fff',
    width: '40%',
    display: 'inline-block',
    margin: 'auto',
    boxShadow: '4px 4px #888888a8',
  },
  invalidFeedback: {
    marginTop: '-12px',
    color: 'red',
  },
  wrapData: {
    margin: '10px auto',
    /*'@media (min-width: 414px)': {
      display: 'grid',
      gridTemplateColumns: '30% 70%',
    },*/
  },
  formControl: {
    width: '100%',
    borderRadius: 6,
    fontSize: 'larger',
    lineHeight: 1,
    fontFamily: 'inherit',
    padding: 5,
    border: '1px solid #204889ff',
    '&:focus': {
      outline: 'none',
    },
  },
});

EditProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  member: PropTypes.object,
  history: PropTypes.object,
};

export default compose(
  withStyles(styles),
  inject('member'),
  observer,
)(EditProfile);
