import { extendObservable } from 'mobx';
import config from 'config';
import userImg from '../assets/images/user-img.png';
// import storage from '../store';

const { liff } = window;
let isInit = false;

export class LineProfile {
  constructor() {
    extendObservable(this, {
      userId: null,
    });
    this.initDefaultProfile();
  }

  initDefaultProfile() {
    this.profiles = {
      userId: '',
      displayName: '',
      pictureUrl: '',
      statusMessage: '',
    };
  }
}
let profile = new LineProfile();

class LineHelper {
  async init(liffId) {
    return new Promise((resolve, reject) => {
      if (!isInit) {
        liff.init(
          { liffId },
          () => {
            isInit = true;
            resolve();
            // reject(new Error('LIFF_INIT_MOCK_FAIL'));
          },
          () => {
            console.log('Fail to init LIFF, please run inside LINE only');
            // reject(new Error('LIFF_INIT_FAIL'));
          },
        );
      } else {
        resolve();
      }
    });
  }

  async getProfile() {
    return new Promise((resolve, reject) => {
      if (config.env === 'development') {
        resolve({
          userId: 'U012344566666565656560',
          displayName: 'Unq',
          pictureUrl: userImg,
          statusMessage: '',
        });
      } else if (!isInit) {
        reject(new Error('LIFF_NOT_INIT'));
      } else if (!profile.userId) {
        console.log('liff.isInClient()', liff.isInClient());
        if (liff.isInClient()) {
          liff
            .getProfile()
            .then(pf => {
              profile = pf;
              resolve(profile);
            })
            .catch(err => {
              console.log('getProfile error', err);
              reject(err);
            });
        } else {
          console.log('liff.isLoggedIn()', liff.isLoggedIn());
          if (liff.isLoggedIn()) {
            liff
              .getProfile()
              .then(pf => {
                profile = pf;
                resolve(profile);
              })
              .catch(err => {
                // const mockLineUserId = `MOCKLineID${window.navigator.userAgent.replace(/\D+/g, '')}`;
                console.log('init on get profile error: ', err);
                // const isAutomate = storage.get('is_automate');
                // const mockLineUserId = isAutomate
                //   ? 'MOCKLineIDForTestAutomate'
                //   : 'U450932501e50a23d1c7596167808ff4f';
                // const displayName = isAutomate ? '' : 'Artit$';
                // const imageUrl = isAutomate
                //   ? 'https://azecomsa99.blob.core.windows.net/develop/undefined/20190309/2019030906131170583.png'
                //   : 'https://i.pinimg.com/474x/33/b8/69/33b869f90619e81763dbf1fccc896d8d--lion-logo-modern-logo.jpg';

                // profile = {
                //   userId: mockLineUserId, /// JUNE1234569999
                //   displayName: displayName,
                //   pictureUrl: imageUrl,
                //   statusMessage: 'mock status',
                // };
                // resolve(profile);
                reject(err);
              });
          } else {
            liff.login();
          }
        }
      } else {
        resolve(profile);
      }
    });
  }

  closeWindow() {
    liff.closeWindow();
  }

  openWindow(url, external) {
    liff.openWindow({ url, external });
  }

  async sendMessages(messages) {
    const messagesToSend = Array.isArray(messages) ? messages : [messages];
    liff.sendMessages(messagesToSend);
  }
}

export default new LineHelper();
