import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/styles';
import { Container, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Link } from '@material-ui/core';

export class CardOrderHistory extends React.Component {
  addDetail = () => {
    const { drug, data } = this.props;
    drug.setOrderHistoryDetail(data);
    this.props.history.push('/welfare-cut-details')
  };

  setStatus(status) {
    if (status === 'SENDING') {
      return 'รอดำเนินการ';
    } else {
      return 'จัดส่งแล้ว';
    }
  }

  render() {
    const { data } = this.props;
    const moment = require('moment');
    return (
      <Container>
        <div style={{ marginTop: '1em' }}>
          <Card>
            <CardContent
              style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: '16px',
              }}>
              <Typography>#{`${data.order_id}` || ''}</Typography>
              <Typography>
                {`${moment(data.created_date).format('ll')}` || ''}
              </Typography>

              <Typography
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                {`${this.setStatus(data.status)}` || ''}
                <Link
                  underline="always"
                  onClick={this.addDetail}>
                  ดูรายละเอียดเพิ่มเติม
                </Link>
              </Typography>
            </CardContent>
          </Card>
        </div>
      </Container>
    );
  }
}

const styles = () => ({
  card: {
    width: 'calc(100vw - 32px)',
    height: 'auto',
    position: 'absolute',
    borderRadius: '25px',
    padding: '25px 25px 60px 25px',
    top: '100px',
    border: '2px solid  #DEE2E9',
    backgroundColor: '#DEE2E9',
    display: 'flex',
    flexDirection: 'column',
  },
  tittle: {
    color: '#0f2443',
    width: '100%',
    textAlign: 'center',
  },
  textFooter: {
    // position: 'absolute',
    // top: 0,
    margin: 'auto',
  },
  listButtom: {
    backgroundColor: '#fff',
  },
  detail: {
    //textAlign: 'right',
    bottom: '0',
    position: 'absolute',
    right: '1px',
  },
  footer: {
    backgroundColor: '#0e2443',
    fontSize: '20px',
    color: 'white',
    borderTop: '1px solid #E7E7E7',
    textAlign: 'center',
    padding: '10px',
    position: 'fixed',
    left: '0',
    bottom: '0',
    height: '60px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
});

CardOrderHistory.propTypes = {
  classes: PropTypes.object,
  //key: PropTypes.string,
  drug: PropTypes.object,
  orderID: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
  data: PropTypes.object,
  member: PropTypes.object,
};

export default compose(
  withStyles(styles),
  inject('drug', 'member'),
  observer,
)(CardOrderHistory);
