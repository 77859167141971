import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWithLayout = props => {
  const { component: Component, layout: Layout, title, hiddenBtnBack, ...rest } = props;

  return (
    <Route
      {...rest}
      // eslint-disable-next-line no-unused-vars
      render={({ staticContext, ...restProps }) => (
        <Layout title={title} hiddenBtnBack={hiddenBtnBack}>
          <Component {...restProps} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  title: PropTypes.string,
  hiddenBtnBack: PropTypes.bool,
};

export default RouteWithLayout;
