import React from 'react';
import { Container } from '@material-ui/core';

const NotFound = () => (
  <Container>
    <h2 style={{ textAlign: 'center' }}>ไม่พบหน้าเพจ [{window.location.pathname}]</h2>
  </Container>
);

export default NotFound;
