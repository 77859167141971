import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/styles';
import { Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Profile from 'components/ProfileHeader/ProfileHeader';
import OrderCard from './components/OrderCard/OrderCard';
import { isNullOrEmpty } from 'utils/common';
import { twoDigiNumberWithCommas } from 'utils/common';
import DialogConfirm from './DialogConfirm'

export class ConfirmOrder extends React.Component {
  
  constructor(props){
    super(props);
    this.state={
      open:false
    }
    this.props.drug.setOpenConfirmDialog(false);
  }
  async componentDidMount() {
    try {
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: 'มีข้อผิดพลาดเกิดขึ้น!',
        text: error.message,
      });
    }
  }
  confirm(){
    const { drug } = this.props;
    const drugSelect = drug.getselectList();
    console.log('drugSelect', drugSelect);
    if (isNullOrEmpty(drugSelect.drugList) || drugSelect.sumPrice === 0) {
      Swal.fire({
        type: 'warning',
        title: 'ขออภัย!',
        text: 'คุณยังไม่ได้เลือกรายการยา',
        confirmButtonColor: '#0e2443',
      });
      return;
    }
    drug.setOpenConfirmDialog(true)
    return;
  }
  render() {
    const { classes, drug, member,history} = this.props;
    const profile = member.getProfileJS();
    const drugSelect = drug.getselectList();
    const drugList = drugSelect.drugList;
    // console.log('drugSelect', drugSelect);
    // console.log('drugList', drugList);
    // console.log('sumprice', drugSelect);
    // console.log('this.props', this.props);
    return (
      <React.Fragment>
        <DialogConfirm history={history} />
        <Container className={classes.homeContainer}>
          {/* <Loading show={this.state.loading || member.loading} /> */}

          <Profile img={profile.pictureUrl} />
          <div
            className={classes.card}
            style={{ flex: 0.1, flexDirection: 'column', marginBottom: '2em' }}>
            <div className={classes.tittle}>
              <Typography variant="h6">รายการที่เลือก</Typography>
            </div>

            {isNullOrEmpty(drugList) ? (
              drugList.map((drug, i) => {
                return <OrderCard key={i} data={drug}></OrderCard>;
              })
            ) : (
              <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center">
                <Grid container justify="space-between">
                  <Grid item xs={6}>
                    <Typography variant="h6"> ยอดตัดสวัสดิการ</Typography>
                  </Grid>
                  <Grid item xs={6} align="right">
                    <Typography variant="h6" className={classes.sumprice}>
                      ฿{twoDigiNumberWithCommas(drug.getselectList().sumPrice)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {!isNullOrEmpty(drugList) ? (
              drugList.map((drug, i) => {
                return <OrderCard key={i} data={drug}></OrderCard>;
              })
            ) : (
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.gridButton}>
                <Typography variant="subtitle1">
                  คุณยังไม่ได้เลือกรายการยา
                </Typography>
              </Grid>
            )}
          </div>
          <div className={classes.footer}>
            <Button
              variant="contained"
              className={classes.listButtom}
              onClick={() => {
                this.props.history.push('/drugs-list');
              }}>
              เลือกรายการ
            </Button>
            <span style={{ display: 'inline-block', margin: '0 10px' }}></span>
            <Button
              variant="contained"
              className={classes.submitButton}
              onClick={() => {
                this.confirm();
              }}>
              ยืนยันตัดสวัสดิการ
            </Button>
          </div>

        </Container>
      </React.Fragment>
    );
  }
}

const styles = () => ({
  card: {
    // width: 'calc(100vw - 32px)',
    // height: 'auto',
    // position: 'absolute',
    // borderRadius: '25px',
    // padding: '25px 25px 60px 25px',
    // top: '125px',
    // border: '2px solid  #DEE2E9',
    // backgroundColor: '#DEE2E9',
    // display: 'flex',
    // flexDirection: 'column',
    // overflow: 'scroll',
    // '-webkit-overflow-scrolling': 'touch',
  },
  tittle: {
    color: '#0f2443',
    width: '100%',
    textAlign: 'center',
  },
  input: {
    padding: '0 12px 0 8px',
    height: '36px',
    border: 'none',
    background: 'transparent',
  },
  item: {
    minWidth: 'calc(100vw - 80px)',
    position: 'relative',
    borderRadius: '25px',
  },
  sumprice: {
    color: 'blue',
  },
  textFooter: {
    //position: 'absolute',
    // top: 0,
    margin: 'auto',
    textAlign: 'center',
  },
  textBeforFooter: {
    //position: 'absolute',
    //top: '90px',
    margin: '65% 0px',
    textAlign: 'left',
    backgroundColor: 'white',
    bottom: '0px',
    width: '100%',
    fontSize: '30px',
    color: '#0e2443',
  },
  listButtom: {
    backgroundColor: '#fff',
    width: '45%',
    // padding: '10px',
  },
  submitButton: {
    backgroundColor: '#fff',
    width: '55%',
  },
  footer: {
    backgroundColor: '#0e2443',
    fontSize: '20px',
    color: 'white',
    borderTop: '1px solid #E7E7E7',
    textAlign: 'center',
    padding: '10px',
    position: 'absolute',
    // left: '0',
    bottom: '4%',
    height: '60px',
    width: '92%',
    display: 'flex',
    borderRadius: '5px',
    flexDirection: 'row',
    // paddingBottom: 'calc(1rem + env(safe-area-inset-bottom))',
    '@media (min-width: 375px) and (min-height: 812px)': {
      // height: 42,
      // bottom: '10%',
      // padding: 'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
    },
  },
  footerSummary: {
    backgroundColor: 'white',
    fontSize: '20px',
    color: 'white',
    // borderTop: '1px solid #E7E7E7',
    textAlign: 'center',
    // padding: '10px',
    position: 'fixed',
    left: '0',
    bottom: '7%',
    height: '60px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
});

ConfirmOrder.propTypes = {
  classes: PropTypes.object.isRequired,
  drug: PropTypes.object,
  member: PropTypes.object,
  history: PropTypes.object,
};
export default compose(
  withStyles(styles),
  inject('drug', 'member'),
  observer,
)(ConfirmOrder);
