import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CssBaseline } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import '../../assets/styles/main.css';
// import { Sling as Hamburger } from 'hamburger-react';
import Header from './components/Header';
// import Footer from './components/Footer';

export class MainLayout extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    hiddenBtnBack: PropTypes.bool,
  };

  render() {
    const { classes, title, hiddenBtnBack } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Header hiddenBtnBack={hiddenBtnBack} title={title} />
        <main className={classes.content}>{this.props.children}</main>
        {/* <Footer /> */}
      </div>
    );
  }
}

const styles = () => ({
  root: {},
  content: {
    height: 'calc(100vh - 80px)',
    marginTop: 50,
    marginBottom: 0,
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    '@media (min-width: 375px) and (min-height: 812px)': {
      // iphone X
      height: 'calc(100vh - 120px)',
      marginBottom: 0,
    },
    // '@media (min-width: 414px) and (min-height: 736px)': { // iphone6/7/8 plus
    //   height: 'calc(94vh - 86px)',
    //   marginTop: 50,
    //   marginBottom: 80,
    // },
    // '@media (min-width: 375px) and (min-height: 667px)': { // iphone6/7/8
    //   height: 'calc(94vh - 83px)',
    //   marginTop: 50,
    //   marginBottom: 80,
    // }
  },
});

export default withStyles(styles)(MainLayout);
