import React from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import { Typography, Button, Grid } from '@material-ui/core';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  fontFamily: 'PSLDisplayPro',
  typography: {
    h4: {
      //fontWeight: 500,
      fontSize: '3rem',
      fontFamily: 'PSLDisplayPro',
    },
    body1: {
      //fontWeight: 500,
      fontSize: '1.8rem',
      fontFamily: 'PSLDisplayPro',
    },
    subtitle1: {
      fontSize: '1.5rem',
      fontFamily: 'PSLDisplayPro',
    },
  },
  
});

const paddingButton = createMuiTheme({
  fontFamily: 'PSLDisplayPro',
  MuiButton: {
    text: {
      padding: '0px',
    },
  },
  typography: {
    subtitle1: {
      fontSize: '1.5rem',
      fontFamily: 'PSLDisplayPro',
      padding: '0px 0px 2px 0px',
    },
  },
})
export class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    try {
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: 'มีข้อผิดพลาดเกิดขึ้น!',
        text: error.message,
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
        <Grid>
          <Container className={classes.homeContainer}>
            {/* <Loading show={this.state.loading || member.loading} /> */}
              <div
                className={classes.card}
                style={{ flex: 0.1, flexDirection: 'column' }}>
                <div className={classes.tittle}>
                  <SuccessIcon
                    className={classes.iconSuccess}
                    fontSize="large"
                  />
                  <Typography variant="h4">ดำเนินการสำเร็จ</Typography>
                </div>
                <div className={classes.normal}>
                  <Typography variant="body1">
                    เจ้าหน้าที่จะติดต่อกลับภายหลัง <br></br>
                    ภายใน 3 ชั่วโมง <br></br>
                    เวลาทำการ <br></br>
                    จันทร์ – ศุกร์ 09.00 น. – 17.00 น. <br></br>
                  </Typography>
                </div>
              </div>
            
          </Container>
          <Container>
          <ThemeProvider theme={paddingButton}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.gridButton}>
                <Button
                  className={classes.buttonDrugList}
                  onClick={() => {
                    this.props.history.push('/drugs-list');
                  }} //className={classes.buttoncenter}
                  >
                  เลือกรายการยาสวัสดิการ
                </Button>
              </Grid>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.gridButton}>
                <Button
                  className={classes.buttonHistory}
                  onClick={() => {
                    this.props.history.push('/welfare-cut-history');
                  }} //className={classes.buttoncenter}
                  >
                  ประวัติการตัดสวัสดิการ
                </Button>
              </Grid>
              </ThemeProvider>
          </Container>
        </Grid>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

const styles = () => ({
  card: {
    width: 'calc(100vw - 32px)',
    height: 'auto',
    position: 'absolute',
    borderRadius: '25px',
    padding: '25px 25px 40px 25px',
    top: '100px',
    border: '2px solid  #DEE2E9',
    backgroundColor: '#DEE2E9',
    display: 'flex',
    flexDirection: 'column',
  },
  tittle: {
    color: '#0f2443',
    width: '100%',
    textAlign: 'center',
    fontSize: '30px',
  },
  normal: {
    color: 'gray',
    width: '100%',
    textAlign: 'center',
  },
  buttonDrugList: {
    fontSize: '1.5rem',
      fontFamily: 'PSLDisplayPro',
      padding: '0px 0px 2px 0px',
    background: '#0e2443',
    color: 'white',
    width: '60%',
    height: 42,
    position: 'fixed',
    textAlign: 'center',
    top: '66%',
    margin: '65px 0px',
    boxShadow: '4px 4px #888888a8',
    // marginTop: '20%',
    // left: 0,
    '@media (min-width: 375px) and (min-height: 812px)': {
      top: '49%',
      height: 42,
      margin: '70px 0px',
    },
    // '& hover': {
    //   backgroundColor: 'red',
    // },
  },
  buttonHistory: {
    fontSize: '1.5rem',
      fontFamily: 'PSLDisplayPro',
      padding: '0px 0px 2px 0px',
    background: '#0e2443',
    color: 'white',
    width: '60%',
    height: 42,
    position: 'fixed',
    textAlign: 'center',
    margin: '75px 0px',
    boxShadow: '4px 4px #888888a8',
    top: '72%',
    // marginTop: '20%',
    // left: 0,
    '@media (min-width: 375px) and (min-height: 812px)': {
      top: '55%',
      height: 42,
    },
    // '& hover': {
    //   backgroundColor: 'red',
    // },
  },
  gridButton: {
    margin: '10px 0px',
  },
  iconSuccess: {
    color: '#a5dc86',
    fontSize: '4rem',
  },
  buttoncenter:{
      fontSize: '1.5rem',
      fontFamily: 'PSLDisplayPro',
      padding: '0px 0px 2px 0px',
  }
});

export default compose(withStyles(styles), observer)(Success);
