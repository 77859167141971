export default theme => ({
  root: {
    color: 'white',
    '&$selected': {
      fontSize: theme.typography.pxToRem(10),
      color: '#5fffffff',
      '& svg': {
        fill: '#5fffffff',
      },
    },
    '& svg': {
      width: '2.5rem',
      height: '2.5rem',
      fill: 'white',
      marginTop: 0.6,
    },
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    '&, &$selected': {
      fontSize: theme.typography.pxToRem(12),
      '@media (max-width: 360px)': {
        fontSize: theme.typography.pxToRem(9.8),
      },
    },
  },
});
