import { createMuiTheme } from '@material-ui/core';

import MuiBottomNavigation from './overrides/MuiBottomNavigation';
import MuiBottomNavigationAction from './overrides/MuiBottomNavigationAction';
import MuiTab from './overrides/MuiTab';
import MuiTextField from './overrides/MuiTextField';
import MuiButton from './overrides/MuiButton';

import PSLDisplayProTTF from '../assets/fonts/PSLDisplayPro.ttf';
import PSLDisplayProWOFF from '../assets/fonts/PSLDisplayPro.woff';
import PSLDisplayProWOFF2 from '../assets/fonts/PSLDisplayPro.woff2';
import PSLDisplayProSVG from '../assets/fonts/PSLDisplayPro.svg';
import PSLDisplayProEOT from '../assets/fonts/PSLDisplayPro.eot';

const PSLDisplayPro = {
  fontFamily: 'PSLDisplayPro',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 100,
  src: `url(${PSLDisplayProTTF}) format('ttf'),
    url(${PSLDisplayProWOFF}) format('woff'),
    url(${PSLDisplayProWOFF2}) format('woff2'),
    url(${PSLDisplayProSVG}) format('svg'),
    url(${PSLDisplayProEOT}) format('eot'),
  `,
};

const theme = createMuiTheme({
  typography: {
    fontFamily: ['PSLDisplayPro', 'sans-serif'],
    fontSize: 20,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [PSLDisplayPro],
      },
    },
  },
});

theme.overrides = {
  MuiBottomNavigation,
  MuiBottomNavigationAction: MuiBottomNavigationAction(theme),
  MuiTab,
  MuiTextField,
  MuiButton,
};

export default theme;
