import React from 'react';
import { Router } from 'react-router-dom';

import { Provider } from 'mobx-react';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/th';
import MomentUtils from '@date-io/moment';

import theme from 'theme';
import allStore from 'store';
import Routes from 'Routes';
import history from 'utils/history';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/styles/index.scss';

moment.locale('th');

export default class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Provider {...allStore}>
            <Router history={history}>
              <React.Fragment>
                <Routes />
              </React.Fragment>
            </Router>
          </Provider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}
