import configDevelopment from './config.development';
import configProduction from './config.production';
import configStaging from './config.staging';

const setupConfig = host => {
  let config = configDevelopment;
  console.log(`host:[${host}] v2`);
  switch (host) {
    case 'extaplus.emetworks.tech':
      config = configProduction;
      break;
    case 'extaplus-web-prd.emetworks.tech':
      config = configProduction;
      break;
    case 'extaplus-dev.emetworks.tech':
      config = configStaging;
      break;
    default:
      config = configDevelopment;
      break;
  }
  console.log('Environment ->', config.env);
  return config;
};

const config = {
  ...setupConfig(window.location.host),
  publicUrl: process.env.PUBLIC_URL,
};

export default config;
