import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ProfileHeader from 'components/ProfileHeader/ProfileHeader';
import { twoDigiNumberWithCommas } from 'utils/common';

export class MoreDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      historyWelfare: [],
    };
  }

  async componentDidMount() {
    try {
      const { member } = this.props;
      await member.loadHistoryWelfare();
      const historyWelfare = member.getHistoryWelfareJS();
      this.setState({ historyWelfare });
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: 'มีข้อผิดพลาดเกิดขึ้น!',
        text: error.message,
      });
    }
  }

  setStatus(status) {
    const textStatus =
      status === 'SENDING'
        ? 'รอดำเนินการ'
        : status === 'SENDING_SUCESS'
        ? 'จัดส่งแล้ว'
        : 'ยกเลิกการจัดส่ง';
    return textStatus;
  }

  render() {
    const { classes, drug, member } = this.props;
    const { historyWelfare } = this.state;
    const HistoryDetail = drug.getOrderHistoryDetailJS();
    const moment = require('moment');
    const druglists = HistoryDetail.drugs;
    const profile = member.getProfileJS();

    console.log('historyWelfare', historyWelfare); // ประวัติตัดสวัสดิการ จาก db ดึงจาก store
    console.log('HistoryDetail', HistoryDetail); // รายละเอียดสวัสดิการ
    console.log('druglists', druglists);

    return (
      <React.Fragment>
        <Container className={classes.homeContainer}>
          {/* <Loading show={this.state.loading || member.loading} /> */}
          <ProfileHeader img={profile.pictureUrl} />

          <div
            className={classes.card}
            style={{ flex: 0.1, flexDirection: 'column' }}>
            <div className={classes.tittle}>
              <Typography variant="h6" align="center">
                ประวัติการตัดสวัสดิการ #{HistoryDetail.order_id}
              </Typography>
            </div>
            <div style={{ marginTop: '1em' }}>
              <Card className={classes.item}>
                <CardContent
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    maxWidth: '100%',
                    overflowWrap: 'break-word',
                  }}>
                  <Grid container justify="space-between">
                    <Grid item xs={7.5}>
                      <Typography >หมายเลขสวัสดิการ </Typography>
                      <Typography >
                        #{HistoryDetail.order_id}
                      </Typography>
                      <Typography >
                        {' '}
                        {this.setStatus(HistoryDetail.status)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4.5}>
                      <Typography  align="right">
                        {moment(HistoryDetail.created_date).format('ll')}
                      </Typography>
                      <Typography align="right">
                        {' '}
                        เวลา {moment(HistoryDetail.created_date).format(
                          'LT',
                        )}{' '}
                        น.
                      </Typography>
                    </Grid>
                  </Grid>

                  <br></br>
                  <div>
                    <Typography variant="h6">สรุปรายการตัดสวัสดิการ</Typography>
                  </div>
                  {HistoryDetail.drugs.map((drugs, index) => {
                    return (
                      <Grid key={index}>
                        <Grid item xs={12}>
                          <Typography
                            style={{
                              lineHeight: '0.9',
                              overflowWrap: 'any-where',
                            }}
                            variant="body1">
                            {drugs.drug_name}{' '}
                            {drugs.supply ? `(${drugs.supply})` : ''}
                            <br></br>
                            {drugs.common_size_name}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          justify="space-between"
                          className={classes.bluefont}>
                          <Grid item xs={6}>
                            <Typography  variant="body1">
                              x{drugs.amount}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} align="right">
                            <Typography  variant="body1">
                              ฿
                              {twoDigiNumberWithCommas(
                                drugs.price * drugs.amount,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <hr></hr>
                      </Grid>
                    );
                  })}
                  <Grid container item xs={12} justify="space-between">
                    <Grid item xs={8} className={classes.bluefont}>
                      <Typography variant="h6">ยอดรวมตัดสวัสดิการ</Typography>
                    </Grid>
                    <Grid item xs={4} align="right" className={classes.redfont}>
                      <Typography variant="h6">
                        ฿{twoDigiNumberWithCommas(HistoryDetail.summary_price)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const styles = () => ({
  card: {
    width: 'calc(100vw - 32px)',
    // height: 'calc(100vh - 120px)',
    position: 'absolute',
    borderRadius: '25px 25px 0px 0px',
    padding: '25px',
    top: '120px',
    border: '2px solid  #DEE2E9',
    backgroundColor: '#DEE2E9',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
  },
  tittle: {
    color: '#0f2443',
    width: '100%',
    fontSize: '1.3rem',
  },
  item: {
    minWidth: 'calc(100vw - 80px)',
    position: 'relative',
    borderRadius: '18px',
  },
  redfont: {
    color: 'red',
  },
  bluefont: {
    color: '#0f2443',
  },
  fonttop: {
    fontSize: "1rem",
  },
});

MoreDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
};
export default compose(
  withStyles(styles),
  inject('member', 'drug'),
  observer,
)(MoreDetails);
