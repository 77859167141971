import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
// import { inject, observer } from 'mobx-react';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
// import lineHelper from 'utils/LineHelper';
// import config from 'config';
// import { isNullOrEmpty } from 'utils/common';

export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    try {
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: 'มีข้อผิดพลาดเกิดขึ้น!',
        text: error.message,
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Container className={classes.homeContainer}>
          {/* <Loading show={this.state.loading || member.loading} /> */}
          eXta Plus
        </Container>
      </React.Fragment>
    );
  }
}

const styles = () => ({});

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
};
export default compose(withStyles(styles), observer)(Home);
// export default compose(withStyles(styles), inject('member'), observer)(Home);
