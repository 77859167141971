import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AddShoppingCartRoundedIcon from '@material-ui/icons/AddShoppingCartRounded';
import { twoDigiNumberWithCommas } from 'utils/common';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiSnackbar: {
      // Some CSS
      anchorOriginTopCenter: {
        top: '10em',
      },
    },
    MuiAlert: {
      // Some CSS
      root: {
        fontFamily: 'PSLDisplayPro, sans-serif',
        fontWeight: 400,
        fontSize: '1em',
      },
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export class DrugCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  addDrug = () => {
    this.setState({
      open: false,
    });
    this.setState({
      open: true,
    });

    const { drug, data } = this.props;
    return drug.setSelectList(data);
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      open: false,
    });
  };

  render() {
    const { classes, data } = this.props;
    return (
      <div style={{ marginTop: '0.4em' }}>
        <Card className={classes.item}>
          <CardContent
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              height: 'auto',
              alignItems: 'center',
              paddingBottom: '16px',
            }}>
            <div style={{ flex: 'auto' }}>
              <div className={classes.flex}>
                <Typography
                  style={{
                    paddingRight: '5px',
                    lineHeight: '1',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    WebkitLineClamp: '2',
                    maxWidth: '70vw',
                  }}
                  variant="body1">
                  {`${data.drug_name}`}
                </Typography>
                {/* {data.supply && (
                  <Typography
                    style={{
                      paddingRight: '30px',
                      lineHeight: '1',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      WebkitLineClamp: '2',
                      maxWidth: '70vw',
                    }}
                    variant="body1">
                    {`(${data.supply})`}
                  </Typography>
                )} */}
              </div>
              <Typography
                style={{
                  paddingRight: '30px',
                  lineHeight: '1',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: '2',
                  maxWidth: '70vw',
                }}
                variant="body1">
                {`${data.common_size_name}`}
              </Typography>
              <Typography
                style={
                  {
                    //position: 'absolute',
                    // fontSize: '1.6rem',
                    // bottom:'20%',
                  }
                }>
                {data.unit} หน่วยต่อ{data.unit_name}
                <span className={classes.price}>
                  ราคา {twoDigiNumberWithCommas(data.emp_price_vat)} บาท
                  {/* ราคา {twoDigiNumberWithCommas(data.emp_price_vat)} บาท */}
                </span>
              </Typography>
            </div>
            <div style={{ flex: '0 1 auto' }}>
              <AddShoppingCartRoundedIcon
                className={classes.shopIcon}
                onClick={this.addDrug}
              />

              <ThemeProvider theme={theme}>
                <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={this.state.open}
                  autoHideDuration={1500}
                  onClose={this.handleClose}>
                  <Alert onClose={this.handleClose} severity="success">
                    เพิ่มรายการยาสำเร็จแล้ว
                  </Alert>
                </Snackbar>
              </ThemeProvider>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}
const styles = () => ({
  card: {
    width: 'calc(100vw - 32px)',
    minHeight: 'calc(100vh - 100px)',
    position: 'absolute',
    borderRadius: '25px 25px 0 0',
    top: '100px',
    border: '2px solid  #ddd',
    backgroundColor: '#ddd',
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    minWidth: 'calc(100vw - 80px)',
    position: 'relative',
  },
  shopIcon: {
    display: 'flex',
    color: '#48752f',
  },
  price: {
    display: 'inline-block',
    margin: '0 2px',
    color: 'blue',
  },
  flex: {
    display: 'flex'
  }
});

DrugCard.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  drug: PropTypes.object,
};

export default compose(withStyles(styles), inject('drug'), observer)(DrugCard);
