import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  AppBar,
  Toolbar,
  // IconButton,
  Typography,
  // Grid,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { Sling as Hamburger } from 'hamburger-react';

import { withRouter } from 'react-router-dom';
// import logo from 'assets/images/logo/extaplus-logo.png';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import HistoryIcon from '@material-ui/icons/History';
import PersonIcon from '@material-ui/icons/Person';
import ListIcon from '@material-ui/icons/List';

export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pictureUrl: '',
      mobileOpen: false,
      setMobileOpen: false,
      hamberger: false,
    };
  }

  async componentDidMount() {
    try {
    } catch (error) {
      console.log('err', error);
    }
  }

  handleBackClick = () => {
    this.props.history.goBack();
  };
  toggleMenu = () => {
    this.setState({
      mobileOpen: !this.state.mobileOpen,
      hamberger: !this.state.hamberger,
    });
  };

  render() {
    const { classes, title, hiddenBtnBack } = this.props;
    const drawer = (
      <div>
        <div>
          {/* <div
            className={classes.closeImgLogo}
          >
            <img src={logo} alt="eXtaLogo"/>
          </div> */}
          <div
            className={classes.closeIcon}
            onClick={() => {
              this.toggleMenu();
            }}>
            <Hamburger rounded toggled={this.state.hamberger} />
          </div>
        </div>

        <div className={classes.toolbar} />
        <Divider />
        <List>
          <ListItem
            button
            key="รายการยาสวัสดิการ"
            onClick={() => {
              this.toggleMenu();
              this.props.history.push('/drugs-list');
            }}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="รายการยาสวัสดิการ" />
          </ListItem>
          <Divider />
          <ListItem
            button
            key="รายการที่เลือก"
            onClick={() => {
              this.toggleMenu();
              this.props.history.push('/confirm-order');
            }}>
            <ListItemIcon>
              <ListAddCheckIcon />
            </ListItemIcon>
            <ListItemText primary="รายการที่เลือก" />
          </ListItem>{' '}
          <Divider />
          <ListItem
            button
            key="ประวัติการตัดสวัสดิการ"
            onClick={() => {
              this.toggleMenu();
              this.props.history.push('/welfare-cut-history');
            }}>
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary="ประวัติการตัดสวัสดิการ" />
          </ListItem>{' '}
          <Divider />
          <ListItem
            button
            key="แก้ไขข้อมูลส่วนตัว"
            onClick={() => {
              this.toggleMenu();
              this.props.history.push('/profile');
            }}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="แก้ไขข้อมูลส่วนตัว" />
          </ListItem>
        </List>
        <Divider />
      </div>
    );
    return (
      <Fragment>
        <AppBar position="fixed" className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <div
              onClick={() => {
                this.toggleMenu();
              }}>
              {hiddenBtnBack ? (
                <Hamburger rounded toggled={this.state.hamberger} />
              ) : (
                <div className={classes.divHideBtnBack}></div>
              )}
            </div>
            {
              // hiddenBtnBack ? (
              //   <div className={classes.divHideBtnBack}></div>
              // ) : (
              // <IconButton
              //   edge="start"
              //   className={classes.menuButton}
              //   color="inherit"
              //   aria-label="menu"
              //   onClick={this.handleBackClick}>
              //   <ChevronLeftIcon />
              // </IconButton>
              // )
            }
            <Typography variant="h6" className={classes.title}>
              {title || 'eXtaPlus'}
            </Typography>
          </Toolbar>
        </AppBar>

        <Hidden smUp implementation="css">
          <Drawer
            // container={container}
            variant="temporary"
            anchor="left"
            open={this.state.mobileOpen}
            onClose={this.toggleMenu}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open>
            {drawer}
          </Drawer>
        </Hidden>

        {/* <Grid className={classes.headerMenu} container justify="center">
          <Grid item xs={3} sm={2} md={1}>
            {/* <MenuIcon className={classes.menu} /> */}
        {/* {this.state.pictureUrl ? (
              <div className={classes.userimg}>
                <img
                  className={classes.userLineImg}
                  src={this.state.pictureUrl || ''}
                  alt={'altName' || ''}
                />
              </div>
            ) : (
              <AccountCircleIcon className={classes.avatar} />
            )}
          </Grid> */}
        {/* </Grid> */}
      </Fragment>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  history: PropTypes.object,
  hiddenBtnBack: PropTypes.bool,
};

const styles = theme => ({
  root: {
    zIndex: 9,
  },
  avatar: {
    width: '48px !important',
    height: '48px !important',
    margin: 'auto',
    textAlign: 'center',
  },
  userimg: {
    // position: 'relative',
    // border: '5px solid white',
    margin: 'auto',
    textAlign: 'center',
    borderRadius: '50%',
    '& > img': {
      width: '48px !important',
      height: '48px !important',
      // display: 'block',
    },
  },
  userLineImg: {
    // position: 'relative',
    borderRadius: '50%',
  },
  divHideBtnBack: {
    width: '2.142857142857143rem',
    marginRight: 16,
  },
  toolbar: {
    background: '#0e2443',
    minHeight: '50px',
    maxHeight: '50px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    padding: '8px 12px',
  },
  menu: {
    marginTop: '10px',
    position: 'absolute',
    zIndex: 8,
    left: '25px',
  },
  headerMenu: {
    flexGrow: 1,
    textAlign: 'center',
    marginTop: '50px',
    zIndex: 9,
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    paddingRight: '48px',
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: 'white',
  },
  closeImgLogo: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

export default compose(
  withStyles(styles),
  inject('member'),
  observer,
)(withRouter(Header));
