import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { CardOrderHistory } from './components/CardOrderHistory/CardOrderHistory';
import ProfileHeader from 'components/ProfileHeader/ProfileHeader';

export class WelfareCutHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      historyWelfare: [],
    };
  }

  async componentDidMount() {
    try {
      const { member } = this.props;
      await member.loadHistoryWelfare();
      const historyWelfare = member.getHistoryWelfareJS();
      this.setState({ historyWelfare });
      console.log('historyWelfare', historyWelfare);
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: 'มีข้อผิดพลาดเกิดขึ้น!',
        text: error.message,
      });
    }
  }
  setStatus(status){
    if(status=== 'SENDING'){
      return 'รอดำเนินการ'
    }else{
      return  'จัดส่งแล้ว'
    }
  }

  render() {
    const { classes, member,drug } = this.props;
    const { historyWelfare } = this.state;
    const profile = member.getProfileJS();
    console.log('historyWelfare', historyWelfare); // ประวัติตัดสวัสดิการ จาก db ดึงจาก store

    return (
      <React.Fragment>
        <Container className={classes.homeContainer}>
          {/* <Loading show={this.state.loading || member.loading} /> */}
          <ProfileHeader img={profile.pictureUrl} />
          <div
            className={classes.card}
            style={{ flex: 0.1, flexDirection: 'column' }}>
            <div className={classes.tittle}>
              <Typography variant="h6">
                ประวัติการตัดสวัสดิการ
                {/* #0f2443 */}
              </Typography>
            </div>
            <div>
              { historyWelfare.map((data,index) => {
                return <CardOrderHistory
                    key={index}
                    data={data}
                    drug={drug}
                    history={this.props.history}
                    ></CardOrderHistory>;
              } )}
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const styles = () => ({
  card: {
    width: 'calc(100vw - 32px)',
    height: 'auto',
    position: 'absolute',
    borderRadius: '25px',
    padding: '25px 10px 60px 10px',
    top: '120px',
    border: '2px solid  #DEE2E9',
    backgroundColor: '#DEE2E9',
    display: 'flex',
    flexDirection: 'column',
  },
  tittle: {
    color: '#0f2443',
    width: '100%',
    textAlign: 'center',
  },
  search: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    justifyContent: 'space-between',
    height: '36px',
    lineHeight: '36px',
    padding: '8px',
    fontSize: '13px',
    borderRadius: '18px',
    border: '1px solid',
    overflow: 'hidden',
  },
  searchIcon: {
    margin: 0,
    // float: 'right',
    height: '22px',
  },
  input: {
    padding: '0 12px 0 8px',
    height: '36px',
    border: 'none',
    background: 'transparent',
  },
  item: {
    minWidth: 'calc(100vw - 80px)',
    position: 'relative',
    borderRadius: '25px',
  },
  shopIcon: {
    color: '#48752f',
    position: 'absolute',
    right: '20px',
    top: '25px',
  },
  textFooter: {
    // position: 'absolute',
    // top: 0,
    margin: 'auto',
  },
  listButtom: {
    backgroundColor: '#fff',
  },
  footer: {
    backgroundColor: '#0e2443',
    fontSize: '20px',
    color: 'white',
    borderTop: '1px solid #E7E7E7',
    textAlign: 'center',
    padding: '10px',
    position: 'fixed',
    left: '0',
    bottom: '0',
    height: '60px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  detail: {
    //textAlign: 'right',
    bottom: '0',
    position: 'absolute',
    right: '1px',
  },
});

WelfareCutHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  member: PropTypes.object,
  drug: PropTypes.object,
  order: PropTypes.object,
};
export default compose(
  withStyles(styles),
  inject('member','drug'),
  observer,
)(WelfareCutHistory);
