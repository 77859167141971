import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

export class ProfileHeader extends Component {
  render() {
    const {
      classes,
      img,
      altName,
      // name,
    } = this.props;
    return (
      <Container className={classes.wrapper}>
        <div className={classes.wrapImgUser}>
          {img ? (
            <div className={classes.userimg}>
              <img
                className={classes.userLineImg}
                src={img || ''}
                alt={altName || ''}
              />
            </div>
          ) : (
            <AccountCircleIcon className={classes.avatar} />
          )}
        </div>
        {/* <Typography variant="h5" className={classes.nameuser}>{name}</Typography> */}
      </Container>
    );
  }
}

const styles = () => ({
  wrapper: {
    color: '#204889',
    width: '60%',
    maxWidth: 300,
    margin: '0 auto 0px',
    textAlign: 'center',
  },
  userimg: {
    position: 'relative',
    border: '5px solid white',
    borderRadius: '50%',
    marginTop: 5,
    '& > img': {
      width: '100%',
      display: 'block',
    },
  },
  userLineImg: {
    position: 'relative',
    borderRadius: '50%',
  },
  nameuser: {
    fontFamily: 'PSLDisplayProBold',
    lineHeight: 1,
    marginTop: 10,
  },
  wrapImgUser: {
    position: 'relative',
    width: '35%',
    margin: '0 auto',
  },
  avatar: {
    width: '48px !important',
    height: '48px !important',
    margin: 'auto',
    textAlign: 'center',
  },
});

ProfileHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  img: PropTypes.string,
  altName: PropTypes.string,
  name: PropTypes.string,
};

export default withStyles(styles)(ProfileHeader);
